import { Button } from 'antd';

import { WhatsAppOutlined } from '@ant-design/icons';
import { Pedidos } from '../sdk/@types/Pedidos';

function modificaPedidos(
  dadosLead: Pedidos.ListaIdPedidos
): Pedidos.ListaIdPedidosComCliente {
  //console.log(dadosLead);
  let pedidoAtual: Pedidos.ListaIdPedidosComCliente = [];
  console.log(dadosLead);
  dadosLead.forEach((element) => {
    const pedidoAtualAux: any = {
      token: element.token,
      id: element.id,
      consumidor_local: element.consumidor_local,
      data: element.data,
      total_pedido: element.total_pedido,
      pedido: element.pedido,
      cliente: JSON.parse(atob(element.pedido as string)).nome,
    };

    pedidoAtual.push(pedidoAtualAux);

    //const pedido = JSON.parse(atob(element.pedido as string));
  });

  // console.log(dadosLead);

  return pedidoAtual;
}

export default modificaPedidos;
