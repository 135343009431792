import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import FormularioDeProdutos from '../features/produtos/FormularioDeProdutos';

function CadastraProdutosView() {
  usePageTitle('Cadastro de Produtos');
  useBreadcrumb('Home/Produtos/Cadastro');
  return <FormularioDeProdutos></FormularioDeProdutos>;
}

export default CadastraProdutosView;
