import { useCallback, useState } from 'react';
import CategoriaService from '../../sdk/services/Categoria.service';

export default function useCategoria() {
  const [categoria, setCategoria] = useState<any>();

  const fetchCategoria = useCallback((categoriaId: string) => {
    CategoriaService.getById(categoriaId)
      .then((categoria: any) => {
        return categoria;
      })
      .then(setCategoria);
  }, []);

  return {
    categoria,
    fetchCategoria,
  };
}
