import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import FormularioDeCategoria from '../features/FormularioDeCategoria';

function CadastraCategoriaView() {
  usePageTitle('Cadastro de Categorias');
  useBreadcrumb('Home/Categorias/Cadastro');
  return <FormularioDeCategoria></FormularioDeCategoria>;
}

export default CadastraCategoriaView;
