import { useCallback, useState } from 'react';
import { Lojas } from '../../sdk/@types/Restaurantes';
import CupomService from '../../sdk/services/Cupom.service';
import LojaService from '../../sdk/services/Loja.service';

export default function useCupom(chaveDeAcesso: string) {
  const [cupom, setCupom] = useState<any>();

  const fetchcupom = useCallback((chaveDeAcesso: string) => {
    CupomService.getCupomByChave(chaveDeAcesso).then(trataNota).then(setCupom);
  }, []);

  function trataNota(dados: any): any {
    console.log(dados);
    return dados;
  }

  return {
    cupom,
    fetchcupom,
  };
}
