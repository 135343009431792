import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserService } from '../../sdk';
import { Lojas } from '../../sdk/@types/Restaurantes';

import LojaService from '../../sdk/services/Loja.service';
import ProdutosService from '../../sdk/services/Produtos.service';

export default function useProduto() {
  const [produto, setProduto] = useState<any>();

  const fetchProduto = useCallback((produtoId: string, restauranteId: string) => {
    ProdutosService.getById(produtoId, restauranteId)
      .then((produto: any) => {
        console.log('produtoprodutoprodutoproduto');
        console.log(produto);
        return produto;
      })
      .then(setProduto);
  }, []);

  return {
    produto,
    fetchProduto,
  };
}
