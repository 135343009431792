import { Pedidos } from '../sdk/@types/Pedidos';

function transformaTabelaCupons(
  cupons: Pedidos.cupomFiscalEmbedded
): Pedidos.tabelaCuponsFiscais[] {
  let tabelaCupons: Pedidos.tabelaCuponsFiscais[] = [];
  if (cupons._embedded) {
    cupons._embedded.cupomFiscal.map((cupomFiscal) => {
      console.log('inicio do map');
      console.log(cupomFiscal);

      //cupomFiscal.endereco.logradouro + cupomFiscal.endereco.numero

      const dataAux: any = {
        token: cupomFiscal.token,
        nome: cupomFiscal.nomeCompleto,
        frete: cupomFiscal.totalFrete,
        subtotal: cupomFiscal.subtotal,
        total: cupomFiscal.total,
        nnf: cupomFiscal.nnf,
        endereco: '',
        serie: cupomFiscal.serie,
        dataEmissao: cupomFiscal.dataEmissao,
        chaveDeAcesso: cupomFiscal.chaveDeAcesso,
      };
      console.log(dataAux);

      const data: Pedidos.tabelaCuponsFiscais = {
        token: cupomFiscal.token,
        nome: cupomFiscal.nomeCompleto,
        frete: cupomFiscal.totalFrete,
        subtotal: cupomFiscal.subtotal,
        total: cupomFiscal.total,
        endereco: '',
        nnf: cupomFiscal.nnf,
        serie: cupomFiscal.serie,
        dataEmissao: cupomFiscal.dataEmissao,
        chaveDeAcesso: cupomFiscal.chaveDeAcesso,
      };
      console.log(data);
      tabelaCupons.push(data);
    });
  }

  return tabelaCupons;
}

export default transformaTabelaCupons;
