import TabelaCupons from '../../componentes/TabelaCupons';
import TabelaUsuarios from '../../componentes/TabelaPedidos';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaClientesView() {
  usePageTitle('Lista de Cupons');
  useBreadcrumb('Home/Cupons/Consulta');
  return <TabelaCupons></TabelaCupons>;
}

export default ConsultaClientesView;
