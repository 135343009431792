import { notification, Button, Col, Row, Form, Input, Select, Tooltip } from 'antd';
import { MaskedInput } from 'antd-mask-input';

import Skeleton from 'antd/lib/skeleton';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import transformarPedidos from '../../componentes/TransformarPedidos';
import useAuth from '../../core/hooks/useAuth';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import { Nfe } from '../../sdk/@types/Nfe';
import { Pedidos } from '../../sdk/@types/Pedidos';
import CupomService from '../../sdk/services/Cupom.service';
import LojaService from '../../sdk/services/Loja.service';
import PedidosService from '../../sdk/services/Pedidos.service';
import useGetParametro from '../../sdk/utils/hooks/useGetParametro';
import useGetToken from '../../sdk/utils/hooks/useGetParametro';
import TrataCupom from '../../sdk/utils/TrataCupom';

function GerarNotaView() {
  usePageTitle('Gerar Nota');
  useBreadcrumb('Home/Pedidos/Consulta/Gerar Nota');

  const [pedido, setPedido] = useState<Pedidos.CupomCompletoPedidoDecodificado>();
  const { user } = useAuth();
  const token = new URLSearchParams(window.location.search).get('token');
  const id = new URLSearchParams(window.location.search).get('id');
  const terminal = new URLSearchParams(window.location.search).get('terminais');
  const [loading, setLoading] = useState<boolean>(false);
  const [parametros, setParametos] = useState<Nfe.Parametros>();
  const [retornoNota, setRetornoNota] = useState<any>();
  const [cpfCampo, setCpfCampo] = useState('');
  const [mascara, setMascara] = useState('111.111.111-11');

  let tokenTechsapp = user?.restaurantes?.basic_token as string;
  let plataforma = user?.restaurantes?.dominio as string;
  useEffect(() => {
    if (tokenTechsapp) {
      setLoading(true);
      if (token) {
        PedidosService.getByToken(token, tokenTechsapp, plataforma)
          .then(transformarPedidos)
          .then((resp) => {
            console.log(resp);
            setPedido(resp);
            setCpfCampo(resp.pedidoDecodificado.cpf);
          });
      }
      if (id) {
        PedidosService.getPedidoById(id, tokenTechsapp, plataforma)
          .then(transformarPedidos)
          .then((resp) => {
            console.log(resp);
            setPedido(resp);
            setCpfCampo(resp.pedidoDecodificado.cpf);
          });
      }

      setLoading(false);
    }
  }, [id, plataforma, token, tokenTechsapp]);

  useEffect(() => {
    LojaService.getParametros().then(setParametos);
  }, []);

  function validaDocumento(documento: string) {
    documento = documento.replace(/\D/g, '');

    // Verifica se é CPF
    if (documento.length === 11) {
      if (/^(\d)\1+$/.test(documento)) return false;

      let soma = 0;
      let resto;

      // Calcula o primeiro dígito verificador
      for (let i = 1; i <= 9; i++) {
        soma += parseInt(documento.substring(i - 1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(documento.substring(9, 10))) return false;

      soma = 0;
      // Calcula o segundo dígito verificador
      for (let i = 1; i <= 10; i++) {
        soma += parseInt(documento.substring(i - 1, i)) * (12 - i);
      }
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(documento.substring(10, 11))) return false;

      return true;
    }
    // Verifica se é CNPJ
    else if (documento.length === 14) {
      let tamanho = documento.length - 2;
      let numeros = documento.substring(0, tamanho);
      let digitos = documento.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
      }

      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== parseInt(digitos.charAt(0))) return false;

      tamanho = tamanho + 1;
      numeros = documento.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== parseInt(digitos.charAt(1))) return false;

      return true;
    }

    return false; // Se não for nem CPF nem CNPJ
  }

  function manterApenasNumeros(documento: string) {
    return documento.replace(/\D/g, '');
  }
  const handleCpfChange = (e: any) => {
    const { value } = e.target;

    if (manterApenasNumeros(value).length == 11) {
      return setCpfCampo(formatarCPF(value));
    }
    if (manterApenasNumeros(value).length == 14) {
      return setCpfCampo(formatarCnpj(value));
    }
    if (manterApenasNumeros(value).length > 14) {
      return;
    }
    return setCpfCampo(value);
  };

  const formatarCnpj = (cnpj: any) => {
    const numerosCnpj = cnpj.replace(/\D/g, '');
    return numerosCnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  };

  const formatarCPF = (cpf: any) => {
    const numerosCpf = cpf.replace(/\D/g, '');
    return numerosCpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
  };
  if (!pedido) return <Skeleton />;

  const formatarTelefone = (numero: any) => {
    const numeroLimpo = numero.replace(/\D/g, '');

    if (numeroLimpo.length === 11) {
      return `(${numeroLimpo.slice(0, 2)}) ${numeroLimpo.slice(2, 7)}-${numeroLimpo.slice(
        7
      )}`;
    } else if (numeroLimpo.length === 10) {
      return `(${numeroLimpo.slice(0, 2)}) ${numeroLimpo.slice(2, 6)}-${numeroLimpo.slice(
        6
      )}`;
    } else {
      return numero;
    }
  };

  return (
    <div>
      <strong>
        AVISO: Para melhor experiência na emissão, habilite os popups para este site.
      </strong>
      {retornoNota ? (
        <div>
          <br />
          {retornoNota.documento.sitDescricao}
          <p
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button type='primary'>
              <Link to={'/pedidos'}>Voltar</Link>
            </Button>

            <Tooltip title={'Imprimir'} placement={'top'}>
              <Button
                type='primary'
                onClick={async () => {
                  const cupom = await CupomService.getCupomByChave(
                    retornoNota.documento.docChaAcesso
                  );
                  if (cupom._embedded) {
                    // The Base64 string of a simple PDF file
                    var b64 = cupom._embedded.historico[0].pdfBase64;

                    // Decode Base64 to binary and show some information about the PDF file (note that I skipped all checks)
                    var bin = atob(b64);

                    // Embed the PDF into the HTML page and show it to the user
                    var obj = document.createElement('object');
                    obj.style.width = '100%';
                    obj.style.height = '842pt';
                    obj.type = 'application/pdf';
                    obj.data = 'data:application/pdf;base64,' + b64;
                    document.body.appendChild(obj);

                    // Insert a link that allows the user to download the PDF file
                    var link = document.createElement('a');
                    link.innerHTML = 'Download PDF file';
                    link.download = 'PDF_' + retornoNota.documento.docChaAcesso + '.pdf';
                    link.href = 'data:application/octet-stream;base64,' + b64;
                    document.body.appendChild(link);
                    link.click();
                  } else {
                    notification.error({
                      message: 'Não foi possível baixar o PDF',
                    });
                  }
                }}
              >
                Imprimir
              </Button>
            </Tooltip>
          </p>
        </div>
      ) : (
        <Form
          autoComplete={'off'}
          layout={'horizontal'}
          onFinish={async (form: any) => {
            form.cpf = cpfCampo;
            console.log(form.cpf);

            try {
              console.log(form);

              if (form.cpf === undefined) {
                console.log('sem cpf');
                form.nome = '';
                form.cpf = '';
              }

              if (form.cpf !== undefined && form.cpf !== '') {
                console.log('Com cpf');

                if (validaDocumento(form.cpf)) {
                  form.cpf = form.cpf.replace(/\D/g, '');
                  if (form.nome === undefined || form.nome === '') {
                    form.nome = 'Cliente Identificado';
                  }
                } else {
                  window.alert('cpf inválido');
                  return;
                }
              }

              console.log(form.nome);

              setLoading(true);
              const resp = await CupomService.getGerarCupom(
                token as string,
                id as string,
                tokenTechsapp,
                plataforma,
                terminal ? terminal : '',
                form.email,
                form.nome,
                form.cpf,
                user,
                form.indPag,
                parametros as Nfe.Parametros,
                form.indPres,
                form.tpEmis,
                form.tPag
              );
              console.log('após achamada');
              setRetornoNota(resp);
              console.log(resp);
              if (resp?.documento.sitCodigo === 100) {
                if (pedido?.pedidoDecodificado) {
                  const resposta = await PedidosService.confirmarCupom(
                    Number(pedido?.pedidoDecodificado.token),
                    {
                      chave_cupom: pedido?.pedidoDecodificado.token,
                      numero_cupom: pedido?.pedidoDecodificado.token,
                    },
                    tokenTechsapp,
                    plataforma
                  );
                  if (resposta != null) {
                    window.location.href = '/';
                  }
                }

                notification.success({
                  message: 'A nota foi gerada com sucesso!',
                });
                window.scrollTo(0, 0);
                setLoading(false);
              } else {
                setLoading(false);
              }
            } catch (error) {
              setLoading(false);
            }
          }}
        >
          <Row align={'middle'} gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label={'Email'} name={'email'}>
                <Input type='email' placeholder={'E.g.: contato@joao.silva'} />
              </Form.Item>
              <Form.Item label='CPF / CNPJ:' name='cpf'>
                <Input
                  type='text'
                  placeholder='Ex.: 000.000.000-00'
                  onChange={handleCpfChange}
                  value={cpfCampo}
                />
                <span>
                  Esse campo é obrigatório para identificar o cliente na nota fiscal.
                </span>
              </Form.Item>
              <Form.Item
                label={'Nome'}
                name={'nome'}
                initialValue={pedido.pedidoDecodificado.nome}
              >
                <Input type='text' placeholder={'E.g.: xxxxxxxxxxxxxx'} />
              </Form.Item>
              <Form.Item
                initialValue={'0'}
                label={'Indicador de Pagamento'}
                name={'indPag'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select placeholder={'Selecione o Indicador'} optionFilterProp='children'>
                  <Select.Option value={'0'}>À Vista</Select.Option>
                  <Select.Option value={'1'}>À Prazo</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                initialValue={'01'}
                label={'Tipo de Pagamento'}
                name={'tPag'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select placeholder={'Selecione o Tipo'} optionFilterProp='children'>
                  <Select.Option value={'01'}>Dinheiro</Select.Option>
                  <Select.Option value={'02'}>Cheque</Select.Option>
                  <Select.Option value={'03'}>Cartão de Crédito</Select.Option>
                  <Select.Option value={'04'}>Cartão de Débito</Select.Option>
                  <Select.Option value={'05'}>Crédito Loja</Select.Option>
                  <Select.Option value={'10'}>Vale Alimentação</Select.Option>
                  <Select.Option value={'11'}>Vale Refeição</Select.Option>
                  <Select.Option value={'12'}>Vale Presente</Select.Option>
                  <Select.Option value={'13'}>Vale Combustível</Select.Option>
                  <Select.Option value={'15'}>15-Boleto bancário</Select.Option>
                  <Select.Option value={'16'}>Depósito Bancário</Select.Option>
                  <Select.Option value={'17'}>Pagamento Instantâneo (PIX)</Select.Option>
                  <Select.Option value={'18'}>
                    Transferência bancária, Carteira Digital
                  </Select.Option>
                  <Select.Option value={'19'}>
                    Programa de fidelidade, Cashback, Crédito Virtual
                  </Select.Option>
                  <Select.Option value={'90'}>Sem pagamento</Select.Option>
                  <Select.Option value={'99'}>Outros</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={'Indicador de Presença'}
                name={'indPres'}
                initialValue={'1'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select placeholder={'Selecione o Indicador'} optionFilterProp='children'>
                  <Select.Option value={'0'}>Não se aplica</Select.Option>
                  <Select.Option value={'1'}>Operação presencial</Select.Option>
                  <Select.Option value={'2'}>
                    Operação não presencial, pela Internet
                  </Select.Option>
                  <Select.Option value={'3'}>
                    Operação não presencial, Teleatendimento
                  </Select.Option>
                  <Select.Option value={'4'}>
                    NFC-e em operação com entrega a domicílio
                  </Select.Option>
                  <Select.Option value={'5'}>
                    Operação presencial, fora do estabelecimento
                  </Select.Option>
                  <Select.Option value={'9'}>
                    Operação não presencial, outros.
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={'Tipo de Emissão'}
                name={'tpEmis'}
                initialValue={'1'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <Select placeholder={'Selecione a Emissão'} optionFilterProp='children'>
                  <Select.Option value={'1'}>Emissão normal</Select.Option>
                  <Select.Option value={'2'}>Contingência FS </Select.Option>
                  <Select.Option value={'3'}>Contingência SCAN</Select.Option>
                  <Select.Option value={'4'}>Contingência DPEC</Select.Option>
                  <Select.Option value={'5'}>Contingência FS-DA</Select.Option>
                  <Select.Option value={'6'}>Contingência SVC-AN</Select.Option>
                  <Select.Option value={'7'}>Contingência SVC-RS</Select.Option>
                  <Select.Option value={'9'}>
                    Contingência off-line da NFC-e
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div>
            Pedido {pedido?.cupomCompleto.token}
            <br />
            {pedido?.cupomCompleto.data}
            <br />
            {pedido?.pedidoDecodificado.nome_loja}
            <br />
            {pedido?.pedidoDecodificado.bairroTratadoIntegracao} -{' '}
            {pedido?.pedidoDecodificado.cidadeIntegracao}
            <br />
            -----------------------------------------
            <br />
            Nome: {pedido?.pedidoDecodificado.nome}
            <br />
            Telefone:{' '}
            {pedido?.pedidoDecodificado.telefoneCupom
              ? formatarTelefone(pedido?.pedidoDecodificado.telefoneCupom)
              : ''}
            <br />
            CPF / CNPJ:{' '}
            {pedido?.pedidoDecodificado.cpf
              ? formatarCPF(pedido?.pedidoDecodificado.cpf)
              : ''}
            <br />
            {pedido?.pedidoDecodificado.pagamento}
            <br />
            Subtotal{' '}
            {parseFloat(pedido?.pedidoDecodificado.subtotal).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
            <br />
            Frete{' '}
            {pedido?.pedidoDecodificado.frete.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
            <br />
            Total{' '}
            {parseFloat(pedido?.pedidoDecodificado.total).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
            <br />
            -----------------------------------------
            <br />
            {pedido?.pedidoDecodificado.produtos.map((element) => {
              let opcionais = '';

              if (element.opcionais && Array.isArray(element.opcionais)) {
                element.opcionais.map((opcional) => {
                  opcionais += ' ' + opcional.nome.replace(/_/g, ' ');
                });
              }
              return (
                <p>
                  {element.nome + opcionais}{' '}
                  {element.valor.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </p>
              );
            })}
            ---------------------------------------
            <br />
            TechsApp - Venda Pelo WhatsApp
            <br />
            www.techsapp.com.br
            <br />
          </div>
          <Button type={'primary'} htmlType={'submit'} loading={loading}>
            {'Gerar Nota'}
          </Button>
        </Form>
      )}
    </div>
  );
}

export default GerarNotaView;
