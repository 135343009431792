import TabelaCategorias from '../../componentes/TabelaCategoria';
import TabelaCupons from '../../componentes/TabelaCupons';
import TabelaUsuarios from '../../componentes/TabelaPedidos';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaCategoriasView() {
  usePageTitle('Lista de Categorias');
  useBreadcrumb('Home/Categorias/Consulta');
  return <TabelaCategorias></TabelaCategorias>;
}

export default ConsultaCategoriasView;
