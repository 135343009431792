import { Usuarios } from '../../sdk/@types/Usuarios';

function transformarDados(dados: Usuarios.Input): Usuarios.InputUsuario {
  const data1 = {
    nome: dados.nome,
    email: dados.email,
    senha: dados.senha,
  };

  return data1 as Usuarios.InputUsuario;
}

export default transformarDados;
