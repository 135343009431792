import { Col, Form, Input, Row, Select, Button, notification, Switch, Space } from 'antd';

import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { userInfo } from 'os';
import { useEffect, useState } from 'react';
import { Authentication } from '../../auth/Auth';
import { Lojas } from '../../sdk/@types/Restaurantes';
import LojaService from '../../sdk/services/Loja.service';
import { updateUser } from '../../core/store/Auth.slice';
import { useDispatch } from 'react-redux';

import { UserService, Usuarios } from '../../sdk';
import ProdutosService from '../../sdk/services/Produtos.service';
import useAuth from '../../core/hooks/useAuth';
import { Produtos } from '../../sdk/@types/Produtos';
import CurrencyInput from '../components/CurrencyInput';
import useProduto from '../../core/hooks/useProduto';
import { useForm } from 'antd/lib/form/Form';
import CategoriaService from '../../sdk/services/Categoria.service';

interface FormulariodeCategoriaSimplificadoProps {
  produto?: any;
  produtoId: string;
  restauranteId: string;
  onSuccess?: () => any;
}

export default function FormulariodeCategoriaSimplificado(
  props: FormulariodeCategoriaSimplificadoProps
) {
  usePageTitle('Lista de Lojas');
  let { user } = useAuth();
  const access_token = localStorage.getItem('accessToken');
  const [reload, setReload] = useState<boolean>(true);
  const [ativo, setAtivo] = useState<boolean>(true);
  const [lojas, setLojas] = useState<Usuarios.comboLojas[]>();
  const [categoria, setCategoria] = useState<any>();
  const [restauranteSelecionado, setRestauranteSelecionado] =
    useState<Usuarios.comboLojas>();
  const [restauranteAtual, setRestauranteAtual] = useState<Usuarios.ListaRestaurantes>();
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ id: string }>();

  const decodedToken: Authentication.AccessTokenDecodedBody = jwtDecode(
    access_token as string
  );

  useEffect(() => {
    if (!categoria) {
      CategoriaService.getById(props.produtoId).then(setCategoria);
    }
    console.log('Buscando categoria');
  }, [categoria]);

  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();
  const history = useHistory();
  const onSearchLoja = (value: string) => {};
  const onChangeLoja = (value: any) => {
    value = JSON.parse(value);

    setRestauranteSelecionado(value as Usuarios.comboLojas);
    UserService.getRestaurantesByID(value.id)
      .then(trataRestauranteAtual)
      .then(setRestauranteAtual);
  };
  let tokenTechsapp = '';
  useEffect(() => {
    if (user?.grupos?._embedded.grupos[0].id == 1) {
      setLojas(trataLojasUsuario(user));
    }
    if (user?.grupos?._embedded.grupos[0].id == 5) {
      UserService.getAllRestaurantes()
        .then(trataLojas)
        .then(setLojas)
        .catch((error) => {
          setError(new Error(error.message));
        });
    }
  }, [tokenTechsapp]);

  const [form] = useForm();

  function trataRestauranteAtual(
    restaurantes: Usuarios.ListaRestaurantes
  ): Usuarios.ListaRestaurantes {
    if (user && restaurantes) {
      let userAux = {
        grupos: user.grupos,
        usuario: user.usuario,
        restaurantes: restaurantes,
      };
      user = userAux;

      userAux.restaurantes = restaurantes;
      dispatch(updateUser(userAux));
    }
    return restaurantes;
  }
  function trataLojas(
    lojas: Usuarios.ArrayDeRestaurantesDoUsuario
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    lojas._embedded.restaurantes.map((restaurante) => {
      const restauranteAux: Usuarios.comboLojas = {
        id: restaurante.id,
        index: indexCombo,
        nome: restaurante.nome,
        dominio: restaurante.dominio,
        token: restaurante.basic_token,
        cnpj: restaurante.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    });

    return comboRestaurantes;
  }

  function trataLojasUsuario(
    usuario: Usuarios.UsuarioDetalhadoGrupo
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    if (usuario.restaurantes) {
      const restauranteAux: Usuarios.comboLojas = {
        id: usuario.restaurantes.id,
        index: indexCombo,
        nome: usuario.restaurantes.nome,
        dominio: usuario.restaurantes.dominio,
        token: usuario.restaurantes.basic_token,
        cnpj: usuario.restaurantes.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    }

    return comboRestaurantes;
  }

  function trataSwitch() {
    setAtivo(!ativo);
    console.log(ativo);
  }

  return (
    <>
      {user?.grupos?._embedded.grupos[0].id == 5 ? (
        <Col xs={24} lg={13}>
          <Form.Item label={'Loja'} name={'loja'} style={{ width: '70%' }}>
            <Select
              placeholder={'Selecione o loja'}
              showSearch
              onChange={onChangeLoja}
              onSearch={onSearchLoja}
              optionFilterProp='children'
              defaultValue={lojas ? lojas[0].nome : ''}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {lojas?.map((restaurante) => (
                <Select.Option value={JSON.stringify(restaurante)}>
                  {restaurante.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        ''
      )}
      {categoria ? (
        <Form
          form={form}
          autoComplete={'off'}
          layout={'horizontal'}
          initialValues={categoria}
          onFinish={async (formProduto: any) => {
            setLoading(true);
            console.log(ativo);
            formProduto.ativo = ativo;
            console.log(formProduto);

            try {
              let produtoAtualizado: any = {
                id: formProduto.id,
                nome: formProduto.nome
              };

              console.log(produtoAtualizado);

              let produtoResp = await CategoriaService.editarCategoria(
                produtoAtualizado,
                props.produtoId
              );
              setLoading(false);
              if (props.onSuccess) {
                props.onSuccess();
              }

              console.log(categoria);
            } catch (error) {
              setLoading(false);
            }
          }}
        >
          <Row align={'middle'}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Nome'}
                name={'nome'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O nome não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input placeholder={'E.x.: xxxxxxxxxxxx'} />
              </Form.Item>


            </Col>


            <Col
              xs={24}
              lg={24}
              style={{ display: 'flex', justifyContent: 'end', gap: '30px' }}
            >
              <Form.Item>
                <Button type={'primary'} htmlType={'submit'} loading={loading}>
                  Salvar Categoria
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        ''
      )}
    </>
  );
}
