import { Menu, Layout, Drawer, DrawerProps, Button, Typography } from 'antd';
import {
  UserOutlined,
  LaptopOutlined,
  DiffOutlined,
  HomeOutlined,
  TableOutlined,
  PlusCircleOutlined,
  FallOutlined,
  RiseOutlined,
  MenuOutlined,
  FileTextOutlined,
  FileSearchOutlined,
  AimOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useState } from 'react';
import { useMemo } from 'react';
import { SiderProps } from 'antd/lib/layout';
import Modal from 'antd/lib/modal/Modal';
import axios from 'axios';
import LojaService from '../../../sdk/services/Loja.service';
import ModalConfirmacaoSincTodosProdutos from '../../../componentes/ModalConfirmacaoSincTodoProdutos';
import ModalConfirmacaoSincNovosProdutos from '../../../componentes/ModalConfirmacaoSincNovosProdutos';
import useAuth from '../../../core/hooks/useAuth';
import styled from 'styled-components';
import { Card } from 'antd';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function DefaultLayoutSidebar() {
  const { user } = useAuth();
  const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const { lg } = useBreakpoint();

  const history = useHistory();
  const location = useLocation();

  const [show, setShow] = useState(false);

  const SidebarWrapper: React.FC = useMemo(() => (lg ? Sider : Drawer), [lg]);

  const siderProps = useMemo((): SiderProps => {
    return {
      width: 200,
      className: 'site-layout-background no-print',
    };
  }, []);

  const drawerProps = useMemo((): DrawerProps => {
    return {
      visible: show,
      closable: true,
      title: <></>,
      headerStyle: {
        height: 64,
      },
      bodyStyle: {
        padding: 0,
      },
      onClose() {
        setShow(false);
      },
      placement: 'left',
    };
  }, [show]);

  const sidebarProps = useMemo(() => {
    return lg ? siderProps : drawerProps;
  }, [lg, siderProps, drawerProps]);

  function atualizarHistorico(caminho: string) {
    history.push(caminho);
  }
  const [showModal, setShowModal] = useState(false);
  const [showModalNovos, setShowModalNovos] = useState(false);
  const closeModalNovos = () => {
    setShowModalNovos(false);
  };

  const closeModalTodos = () => {
    setShowModal(false);
  };

  const StyledMenuItem = styled.div`
    margin-top: 10px;
    padding: 8px 16px;
    font-weight: bold;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 13px;
  `;
  const { Text } = Typography;
  const nomeL = user?.restaurantes?.nome ? user?.restaurantes.nome : '';
  return (
    <>
      {!lg && (
        <Button
          icon={<MenuOutlined />}
          onClick={() => setShow(true)}
          type={'text'}
          style={{ position: 'fixed', top: 0, left: 0, height: 64, zIndex: 99 }}
        />
      )}
      <SidebarWrapper {...sidebarProps}>
        <Menu
          mode='inline'
          defaultSelectedKeys={[location.pathname]}
          defaultOpenKeys={[location.pathname.split('/')[1]]}
          style={{ height: '100%', borderRight: 0 }}
        >
          <StyledMenuItem>{user?.restaurantes?.nome ? nomeL : ''}</StyledMenuItem>

          <StyledMenuItem>
            {user?.restaurantes?.tp_amb === '1'
              ? 'Produção'
              : user?.restaurantes?.tp_amb === '2'
              ? 'Homologação'
              : ''}
          </StyledMenuItem>

          <Menu.Item
            key={'/'}
            icon={<HomeOutlined />}
            onClick={function (event) {
              setShow(false);
            }}
          >
            <Link to={'/'}>Home</Link>
          </Menu.Item>
          <SubMenu key='pedidos' icon={<FileTextOutlined />} title='Pedidos'>
            <Menu.Item
              key='/pedidos'
              onClick={function (event) {
                atualizarHistorico('/pedidos');
                setShow(false);
              }}
              icon={<FileSearchOutlined />}
            >
              <Link to={'/pedidos'}>Consulta</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key='cuponsfiscais' icon={<FileTextOutlined />} title='Cupons fiscais'>
            <Menu.Item
              key='/cuponsfiscais'
              onClick={function (event) {
                atualizarHistorico('/cuponsfiscais');
                setShow(false);
              }}
              icon={<FileSearchOutlined />}
            >
              <Link to={'/cuponsfiscais'}>Consulta</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu key='produto' icon={<ShopOutlined />} title='Produtos'>
            <Menu.Item
              key='/produtos'
              onClick={function (event) {
                atualizarHistorico('/produtos');
                setShow(false);
              }}
              icon={<FileSearchOutlined />}
            >
              <Link to={'/produto'}>Consulta</Link>
            </Menu.Item>
            <Menu.Item
              key='/produtos/cadastro'
              onClick={function (event) {
                atualizarHistorico('/produtos/cadastro');
                setShow(false);
              }}
              icon={<PlusCircleOutlined />}
            >
              <Link to={'/produto/cadastro'}>Cadastro</Link>
            </Menu.Item>

            <Menu.Item
              key='/produtos/manutencao'
              onClick={function (event) {
                atualizarHistorico('/produtos/manutencao');
                setShow(false);
              }}
              icon={<TableOutlined />}
            >
              <Link to={'/produto/cadastro'}>Manutenção</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu key='categoria' icon={<ShopOutlined />} title='Categorias'>
            <Menu.Item
              key='/categoria'
              onClick={function (event) {
                atualizarHistorico('/categoria');
                setShow(false);
              }}
              icon={<FileSearchOutlined />}
            >
              <Link to={'/produto'}>Consulta</Link>
            </Menu.Item>
            <Menu.Item
              key='/categoria/cadastro'
              onClick={function (event) {
                atualizarHistorico('/categoria/cadastro');
                setShow(false);
              }}
              icon={<PlusCircleOutlined />}
            >
              <Link to={'/categoria/cadastro'}>Cadastro</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key='Sinc Techsapp' icon={<ShopOutlined />} title='Sinc Techsapp'>
            <Menu.Item
              key='/categoria/cadastro'
              onClick={function (event) {
                setShowModalNovos(true);
                setShow(false);
              }}
              icon={<PlusCircleOutlined />}
            >
              <Link to={''}>Novos Produtos</Link>
            </Menu.Item>
            <ModalConfirmacaoSincNovosProdutos
              showModal={showModalNovos}
              closeModal={closeModalNovos}
            ></ModalConfirmacaoSincNovosProdutos>
            <Menu.Item
              key='/categoria/cadastro'
              onClick={function (event) {
                setShowModal(true);
                setShow(false);
              }}
              icon={<PlusCircleOutlined />}
            >
              <Link to={''}>Todos os Produtos</Link>
            </Menu.Item>
            <ModalConfirmacaoSincTodosProdutos
              showModal={showModal}
              closeModal={closeModalTodos}
            ></ModalConfirmacaoSincTodosProdutos>
          </SubMenu>
        </Menu>
      </SidebarWrapper>
    </>
  );
}
