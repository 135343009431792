import TabelaCategorias from '../../componentes/TabelaCategoria';
import TabelaProdutos from '../../componentes/TabelaProdutos';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaProdutosView() {
  usePageTitle('Lista de Produtos');
  useBreadcrumb('Home/Produtos/Consulta');
  return (
    <>
      <TabelaProdutos></TabelaProdutos>
    </>
  );
}

export default ConsultaProdutosView;
