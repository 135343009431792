import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import handleAxiosResponseSuccess from './utils/handleAxiosResponseSuccess';

const Http = axios.create();
const Http2 = axios.create();
const HttpTechNFCE = axios.create();
const HttpApiNfe = axios.create();
const HttpApiTechfood = axios.create();

const AUTH_BASE_URL = process.env.REACT_APP_AUTH_SERVER_BASE_URL;
const NFCE_BASE_URL = process.env.REACT_APP_NFCE_URL;
const HTTPTECHFOOD_BASE_URL = process.env.REACT_APP_TECHFOOD_URL;

class Service {
  protected static Http = Http;
  protected static Http2 = Http2;
  protected static HttpTechNFCE = HttpTechNFCE;
  protected static HttpApiNfe = HttpApiNfe;
  protected static HttpApiTechfood = HttpApiTechfood;

  protected static getData = getData;

  public static setRequestInterceptors(
    onFulfilled: (
      request: AxiosRequestConfig
    ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
    onRejected?: (error: any) => any
  ) {
    Http.interceptors.request.use(onFulfilled, onRejected);
    HttpTechNFCE.interceptors.request.use(onFulfilled, onRejected);
    HttpApiTechfood.interceptors.request.use(onFulfilled, onRejected);
  }

  public static setResponseInterceptors(
    onFulfilled: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
    onRejected: (error: any) => any
  ) {
    Http.interceptors.response.use(onFulfilled, onRejected);
    HttpTechNFCE.interceptors.response.use(onFulfilled, onRejected);
    HttpApiTechfood.interceptors.response.use(onFulfilled, onRejected);
  }
}

function getData<T>(res: AxiosResponse<T>) {
  return res.data;
}

Http.defaults.baseURL = AUTH_BASE_URL;
Http2.defaults.baseURL = '';
HttpTechNFCE.defaults.baseURL = NFCE_BASE_URL;
HttpApiNfe.defaults.baseURL = 'https://www.technfe.com.br';
HttpApiTechfood.defaults.baseURL = HTTPTECHFOOD_BASE_URL;

export default Service;
