import { userInfo } from 'os';
import { useEffect, useState } from 'react';
import { Usuarios } from '..';
import { Nfe } from '../@types/Nfe';
import { Pedidos } from '../@types/Pedidos';
import Service from '../Service';
import PedidosService from './Pedidos.service';
import ProdutosService from './Produtos.service';
import { notification } from 'antd';

let pedidoCodificado: Pedidos.CupomCompleto;
let cupomInput: Pedidos.CupomInput;
let cupomInvoiceInput: Pedidos.CupomInvoiceInput;
let cidadeAux: string;
let cidade: Pedidos.cidade;
let estado: Pedidos.estado;
let cidadeInput: Pedidos.inputCidade;
let estadoAux: string;
let buscouPedido = false;
let buscouCidade = false;
let buscouEstado = false;
let produtoTechFood: any;

class CupomService extends Service {
  static async getGerarCupom(
    token: string,
    id: string,
    tokenTechsapp: string,
    plataforma: string,
    terminal: string,
    email: string,
    nome: string,
    cpf: string,
    user: Usuarios.UsuarioDetalhadoGrupo | null,
    indPag: string,
    parametros: Nfe.Parametros,
    indPres: string,
    tpEmis: string,
    tipoPagamento: string
  ) {
    await PedidosService.getPedidoById(id as string, tokenTechsapp, plataforma).then(
      (finalResult) => {
        pedidoCodificado = finalResult;
        buscouPedido = true;
      }
    );

    while (!buscouPedido && !buscouCidade && !buscouEstado) {
      setTimeout(() => {}, 1000);
    }

    let cupom: string = '';
    let pedido: Pedidos.PedidoTechsapp;
    let tokenNota: string;
    const itensPedido: any = [];
    const detItem: any = [];

    if (pedidoCodificado) {
      tokenNota = pedidoCodificado.token;
      cupom = atob(pedidoCodificado.cupom);
      pedido = JSON.parse(atob(pedidoCodificado.pedido));

      console.log(pedido);
      cidadeAux = pedido.cidadeIntegracao;
      estadoAux = pedido.estadoIntegracao;
      console.log('Antes do if do estado e cidade');
      console.log(cidadeAux);
      console.log(estadoAux);
      if (cidadeAux && estadoAux) {
        console.log('Dentro do if do estado e cidade');
        await PedidosService.getEstado(estadoAux).then((finalResult) => {
          estado = finalResult;
          buscouEstado = true;
        });

        await PedidosService.getCidade(cidadeAux).then((finalResult) => {
          cidade = finalResult;

          buscouCidade = true;
        });
      }

      console.log('Antes do if !cidade._embedded');
      console.log(cidade);
      if (cidade !== undefined) {
        if (!cidade._embedded) {
          console.log('2');
          if (estado._embedded) {
            console.log('3');
            cidadeInput = {
              estado: {
                id: estado._embedded.estados[0].id,
              },
              nome: cidadeAux,
            };
            await PedidosService.newCidade(cidadeInput);

            await PedidosService.getCidade(cidadeAux).then((finalResult) => {
              cidade = finalResult;

              buscouCidade = true;
            });
          } else {
            const estadoInput: Pedidos.inputEstado = {
              nome: estadoAux,
              uf: estadoAux,
            };

            await PedidosService.newEstado(estadoInput);

            await PedidosService.getEstado(estadoAux).then((finalResult) => {
              estado = finalResult;
              buscouEstado = true;

              cidadeInput = {
                estado: {
                  id: estado._embedded.estados[0].id,
                },
                nome: cidadeAux,
              };
            });

            await PedidosService.newCidade(cidadeInput);

            await PedidosService.getCidade(cidadeAux).then((finalResult) => {
              cidade = finalResult;

              buscouCidade = true;
            });
          }
        }
      }
      console.log('Depois do if !cidade._embedded');
      if (user !== null) {
        await Promise.all(
          pedido.produtos.map(async (element3) => {
            let opcionais = '';

            if (element3.opcionais && Array.isArray(element3.opcionais)) {
              element3.opcionais.map((opcional) => {
                opcionais += ' ' + opcional.nome.replace(/_/g, ' ');
              });
            }

            const pedidoAtual = {
              codigo: element3.codigoIntegracao,
              descricaoCompleta: element3.nome,
              descricaoResumida: element3.nome,
              observacao: element3.obs,
              quantidade: element3.quantidade,
              valor: element3.valor,
            };
            produtoTechFood = await ProdutosService.getProdutosTechfoodByCodigoIntegracao(
              element3.codigoIntegracao,
              user.restaurantes?.id as string
            );

            // colocar aqui uma mensagem caso de erro

            console.log('produtoTechFood');
            console.log(produtoTechFood);

            const produtoAtual = {
              prod: {
                cProd: element3.codigoIntegracao,
                cEAN: produtoTechFood.ean ? produtoTechFood.ean : 'SEM GTIN',
                xProd:
                  (user.restaurantes?.tp_amb as string) === '2'
                    ? 'NOTA FISCAL EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL'
                    : element3.nome + opcionais,
                NCM: produtoTechFood.ncm,
                CFOP: produtoTechFood.cfop,
                uCOM: produtoTechFood.unidade ? produtoTechFood.unidade : 'UN',
                qCOM: '' + element3.quantidade + '',
                vUnCom: '' + element3.valor / element3.quantidade + '',
                vProd: '' + element3.valor + '',
                cEANTrib: produtoTechFood.ean ? produtoTechFood.ean : 'SEM GTIN',
                uTrib: produtoTechFood.unidade ? produtoTechFood.unidade : 'UN',
                qTrib: '' + element3.quantidade + '',
                vUnTrib: '' + element3.valor / element3.quantidade + '',
                indTot: '1',
                nTipoItem: produtoTechFood.ntipoItem,
              },
              imposto: {
                ICMS: {
                  orig: produtoTechFood.origem,
                  CST: produtoTechFood.cst,
                },
              },
            };
            console.log('produtoAtual');
            console.log(produtoAtual);
            detItem.push(produtoAtual);
            console.log('Após push detItem');
            console.log(detItem);
            itensPedido.push(pedidoAtual);
          })
        );

        if (user !== null) {
          console.log('detItem');
          console.log(detItem);
          cupomInvoiceInput = {
            ModeloDocumento: parametros.modelo_documento,
            Versao: parametros.versao,
            ide: {
              cNF: parametros.cnf,
              cUF: user.restaurantes?.endereco.cidade.cuf as string,
              natOp: 'Venda',
              indPag: indPag,
              mod: parametros.mod_nfe,
              serie: terminal,
              nNF: '',
              dhEmi: '',
              fusoHorario: '-03:00',
              tpNf: '1',
              idDest: '1',
              indFinal: '1',
              indPres: indPres,
              cMunFg: user.restaurantes?.endereco.cidade.ibge as string,
              tpImp: '4',
              tpEmis: tpEmis,
              tpAmb: user.restaurantes?.tp_amb as string,
              finNFe: '1',
              procEmi: '0',
            },
            emit: {
              CNPJ_emit: user.restaurantes?.cnpj as string,
              xNome: user.restaurantes?.nome as string,
              IE: user.restaurantes?.ie as string,
              // regime tributario
              CRT: user.restaurantes?.crt as string,
              enderEmit: {
                xLgr: user.restaurantes?.endereco.logradouro as string,
                nro: user.restaurantes?.endereco.numero as string,
                xBairro: user.restaurantes?.endereco.bairro as string,
                cMun: user.restaurantes?.endereco.cidade.ibge as string,
                xMun: user.restaurantes?.endereco.cidade.nome as string,
                UF: user.restaurantes?.endereco.cidade.estado as string,
                CEP: user.restaurantes?.endereco.cep as string,
              },
            },
            dest: {
              CPF_dest:
                (user.restaurantes?.tp_amb as string) === '2'
                  ? '39247350859'
                  : cpf
                  ? cpf
                  : '',
              indIEDest:
                (user.restaurantes?.tp_amb as string) === '2' ? '9' : cpf ? '9' : '0',
              //indIEDest: cpf ? '9' : '0',
              xNome_dest:
                (user.restaurantes?.tp_amb as string) === '2'
                  ? 'NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL'
                  : cpf
                  ? nome
                  : '',

              enderDest: {
                nro_dest: null,
                xBairro_dest: null,
                xEmail_dest: email ? email : null,
                xLgr_dest: null,
                cMun_dest: null,
                xMun_dest: null,
                UF_dest: null,
              },
            },
            det: {
              detItem: detItem,
            },
            total: {
              ICMStot: {
                vBC_ttlnfe: '0.00',
                vICMS_ttlnfe: '0.00',
                vICMSDeson_ttlnfe: '0.00',
                vBCST_ttlnfe: '0.00',
                vST_ttlnfe: '0.00',
                vProd_ttlnfe: '10.00',
                vFrete_ttlnfe: '0.00',
                vSeg_ttlnfe: '0.00',
                vDesc_ttlnfe: '0.00',
                vIPI_ttlnfe: '0.00',
                vPIS_ttlnfe: '0.00',
                vCOFINS_ttlnfe: '0.00',
                vOutro: '0.00',
                vNF: '10.00',
                vTotTrib_ttlnfe: '0.00',
              },
            },
            transp: {
              modFrete: '9',
            },
            pag: {
              pagItem: [
                {
                  tPag: tipoPagamento,
                  vPag: pedido.total,
                },
              ],
            },
          };
        }
        console.log(cupomInvoiceInput);

        const retornoApiNfe: Pedidos.RetornoGerarNota =
          await PedidosService.insertNewCupomInvoice(cupomInvoiceInput);

        console.log('retornoApiNfe');
        console.log(retornoApiNfe);
        if (retornoApiNfe.documento.docPDFDownload) {
          pedido.endereco && cidade?._embedded.cidades[0].id
            ? (cupomInput = {
                chaveDeAcesso: retornoApiNfe.documento.docChaAcesso,
                cnpj: user.restaurantes?.cnpj as string,
                cpf: '.',
                loja: user.restaurantes?.id as string,
                usuario: user.usuario.id as string,
                serie: terminal,
                nnf: '' + retornoApiNfe.documento.docNumero,
                endereco: {
                  bairro: pedido.bairro ? pedido.bairro : '.',
                  cep: pedido.cep ? pedido.cep : '.',
                  cidade: {
                    id: cidade?._embedded.cidades[0].id,
                  },
                  complemento: pedido.complemento ? pedido.complemento : '.',
                  logradouro: pedido.ruaIntegracao ? pedido.ruaIntegracao : '.',
                  numero: pedido.numero ? pedido.numero : '0',
                },
                formaPagamento: pedido.pagamento,
                ie: '.',
                itens: itensPedido,
                nomeCompleto: nome ? nome : '.',
                observacao: '.',
                rg: '.',
                subtotal: pedido.subtotal.replace('.', ''),
                telefone: pedido.telefone,
                token: pedido.token,
                total: pedido.total,
                totalFrete: pedido.frete,
                status: 'AUTORIZADO',
                tpamb: user.restaurantes?.tp_amb ? user.restaurantes?.tp_amb : '2',
              })
            : (cupomInput = {
                chaveDeAcesso: retornoApiNfe.documento.docChaAcesso,
                cnpj: user.restaurantes?.cnpj as string,
                cpf: '.',
                loja: user.restaurantes?.id as string,
                usuario: user.usuario.id as string,
                serie: terminal,
                nnf: '' + retornoApiNfe.documento.docNumero,
                formaPagamento: pedido.pagamento,
                ie: '.',
                itens: itensPedido,
                nomeCompleto: nome ? nome : '.',
                observacao: '.',
                rg: '.',
                subtotal: pedido.subtotal,
                telefone: pedido.telefone,
                token: pedido.token,
                total: pedido.total,
                totalFrete: pedido.frete,
                status: 'AUTORIZADO',
                tpamb: user.restaurantes?.tp_amb ? user.restaurantes?.tp_amb : '2',
              });

          const cupom = await CupomService.getCupomByChave(
            retornoApiNfe.documento.docChaAcesso
          );
          if (cupom._embedded) {
            // The Base64 string of a simple PDF file
            var b64 = cupom._embedded.historico[0].pdfBase64;

            // Decode Base64 to binary and show some information about the PDF file (note that I skipped all checks)
            var bin = atob(b64);

            // Embed the PDF into the HTML page and show it to the user
            var obj = document.createElement('object');
            obj.style.width = '100%';
            obj.style.height = '842pt';
            obj.type = 'application/pdf';
            obj.data = 'data:application/pdf;base64,' + b64;
            document.body.appendChild(obj);

            // Insert a link that allows the user to download the PDF file
            var link = document.createElement('a');
            link.innerHTML = 'Download PDF file';
            link.download = 'PDF_' + retornoApiNfe.documento.docChaAcesso + '.pdf';
            link.href = 'data:application/octet-stream;base64,' + b64;
            document.body.appendChild(link);
            link.click();
          } else {
            notification.error({
              message: 'Não foi possível baixar o PDF',
            });
          }
        } else {
          notification.error({
            message: retornoApiNfe.documento.sitDescricao,
          });
        }
        if (cupomInput) {
          await PedidosService.insertNewCupom(cupomInput);
        }
        console.log(retornoApiNfe);
        return retornoApiNfe;
      }
    }
  }

  static getCupomByChave(chaveDeAcesso: string) {
    return this.HttpTechNFCE.get<any>(`v1/historico/chave/${chaveDeAcesso}`).then(
      this.getData
    );
  }

  static reenviarEmail(email: string, id: string) {
    return this.HttpTechNFCE.get<any>(`v1/nota/reenviarEmail/${email}/${id}`).then(
      this.getData
    );
  }
}

export default CupomService;
