import { EyeOutlined } from '@ant-design/icons';
import { Modal, Button, Row, Col, notification, Form, Input, Tooltip } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import useCupom from '../core/hooks/useCupom';

import CupomService from '../sdk/services/Cupom.service';
import NfeService from '../sdk/services/Nfe.service';

const ModalCupons = (props: {
  chaveDeAcesso: string;
  quandoFinalizar: () => any;
  tpamb: string;
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const openCreationModal = useCallback(() => setShowCreationModal(true), []);
  const closeCreationModal = useCallback(() => setShowCreationModal(false), []);
  const [formEmail, setFormEmail] = useState<any>();
  //fazer a busca
  const { cupom, fetchcupom } = useCupom(props.chaveDeAcesso);

  useEffect(() => {
    console.log('antes de buscar');
    console.log(props.chaveDeAcesso);
    if (props.chaveDeAcesso) {
      fetchcupom(props.chaveDeAcesso);
    }
  }, [fetchcupom, props.chaveDeAcesso]);

  useEffect(() => {
    if (cupom) {
      if (cupom._embedded) {
        console.log(cupom._embedded);
        const formEmailAux = {
          email: cupom._embedded?.historico[0].email,
        };
        setFormEmail(formEmailAux);
      }
    }
  }, [cupom]);

  return (
    <>
      <Row align={'middle'} gutter={24}>
        <Col xs={24} lg={12}>
          <Tooltip title={'Visualizar'} placement={'top'}>
            <Button size='small' icon={<EyeOutlined />} onClick={openCreationModal} />
          </Tooltip>
        </Col>
      </Row>
      {cupom ? (
        <Modal
          title='Cupom'
          footer={null}
          visible={showCreationModal}
          onCancel={closeCreationModal}
          destroyOnClose
        >
          <Row>
            <Col
              xs={24}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              Status: {cupom._embedded ? cupom._embedded.historico[0].status : ''}
              <Button
                type={'primary'}
                htmlType={'button'}
                loading={confirmLoading}
                style={{
                  backgroundColor:
                    cupom._embedded?.historico[0].status === 'AUTORIZADO'
                      ? 'red'
                      : '#f5f5f5',
                  borderColor:
                    cupom._embedded?.historico[0].status === 'AUTORIZADO'
                      ? 'red'
                      : '#d9d9d9',
                }}
                disabled={
                  cupom._embedded?.historico[0].status === 'AUTORIZADO' ? false : true
                }
                onClick={async () => {
                  setConfirmLoading(true);
                  console.log(cupom._embedded?.historico[0].id);
                  const respCancelamento = await NfeService.cancelarNota(
                    props.chaveDeAcesso,
                    props.tpamb
                  );
                  console.log(respCancelamento.documento.sitCodigo);
                  if (respCancelamento.documento.sitCodigo === 101) {
                    const respAlterarStatusTechfood =
                      await NfeService.atualizarStatusTechfood(
                        props.chaveDeAcesso,
                        'CANCELADO'
                      );
                    if (respAlterarStatusTechfood === true) {
                      notification.success({
                        message: 'Sucesso',
                        description: 'Nota cancelada!',
                      });
                    }

                    setConfirmLoading(false);
                    props.quandoFinalizar();
                    closeCreationModal();
                  } else {
                    setConfirmLoading(false);
                    notification.error({
                      message: 'Erro',
                      description: 'Falha no cancelamento.',
                    });
                  }
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24}>
              Tipo do Documento:{' '}
              {cupom._embedded ? cupom._embedded.historico[0].tipoDocumento : ''}
            </Col>
            <Col xs={24}>
              Chave De Acesso:{' '}
              {cupom._embedded ? cupom._embedded.historico[0].chaveDeConsulta : ''}
            </Col>
            <Col xs={24}>
              Data de Emissão:{' '}
              {cupom._embedded
                ? moment(cupom._embedded.historico[0].dataEmissao).format('DD/MM/YYYY')
                : ''}
            </Col>
            <Col xs={24}>
              Série: {cupom._embedded ? cupom._embedded.historico[0].serie : ''}
            </Col>
            <Col xs={24}>
              NNF: {cupom._embedded ? cupom._embedded.historico[0].numero : ''}
            </Col>
            <Col xs={24}>
              Documento: {cupom._embedded ? cupom._embedded.historico[0].documento : ''}
            </Col>
            <Col xs={24}>
              E-mail: {cupom._embedded ? cupom._embedded.historico[0].email : ''}
            </Col>
            <Form
              autoComplete={'off'}
              layout={'horizontal'}
              initialValues={formEmail}
              onFinish={async (form: any) => {
                setConfirmLoading(true);
                try {
                  let resp = await CupomService.reenviarEmail(
                    form.email,
                    cupom._embedded.historico[0].id
                  );

                  if (resp === 'Email Enviado com Sucesso') {
                    setConfirmLoading(false);
                    notification.success({
                      message: 'Sucesso',
                      description: 'Email Enviado com Sucesso',
                    });
                  }
                } catch (error) {
                  setConfirmLoading(false);
                }
              }}
            >
              <Row align={'top'} gutter={24} style={{ marginTop: '5px' }}>
                <Col xs={24} lg={16}>
                  <Form.Item
                    label={'Email'}
                    name={'email'}
                    rules={[
                      {
                        required: true,
                        message: 'O campo é obrigatório',
                      },
                      {
                        max: 255,
                        message: `O email não pode ter mais de 255 caracteres`,
                      },
                    ]}
                  >
                    <Input type='email' placeholder={'E.g.: contato@joao.silva'} />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={8}>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    loading={confirmLoading}
                    disabled={
                      cupom._embedded?.historico[0].status === 'AUTORIZADO' ? false : true
                    }
                  >
                    {'Enviar E-mail '}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default ModalCupons;
