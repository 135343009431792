import Service from '../Service';

class CategoriaService extends Service {

  static excluirByID(idCat: string) {
    return this.HttpApiTechfood.delete<any>(
      `/v1/categorias/${idCat}`,
      {}
    ).then(this.getData);
  }

  static cadastrarCategoria(categoria: any, restauranteId: string) {
    return this.HttpApiTechfood.post<any>(
      `/v1/categorias/${restauranteId}`,
      categoria
    ).then(this.getData);
  }


  static editarCategoria(categoria: any, catId: string) {
    return this.HttpApiTechfood.put<any>(
      `/v1/categorias/${catId}`,
      categoria
    ).then(this.getData);
  }

  static getByIdRestaurante(restauranteID: string) {
    return this.HttpApiTechfood.get<any>(
      `/v1/categorias/restaurante/${restauranteID}`
    ).then(this.getData);
  }


  static getById(categoriaID: string) {
    return this.HttpApiTechfood.get<any>(
      `/v1/categorias/${categoriaID}`
    ).then(this.getData);
  }
}

export default CategoriaService;
