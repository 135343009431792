import React from 'react';
import { Modal, notification } from 'antd';
import LojaService from '../sdk/services/Loja.service';
import useAuth from '../core/hooks/useAuth';
import { Lojas } from '../sdk/@types/Restaurantes';

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
}

const ModalConfirmacaoSincNovosProdutos: React.FC<ModalProps> = ({
  showModal,
  closeModal,
}) => {
  let { user } = useAuth();
  return (
    <Modal
      title='Confirmação'
      visible={showModal}
      onOk={async () => {
        if (user?.restaurantes) {
          console.log(user.restaurantes.basic_token);
          console.log(user?.restaurantes.dominio);
          let respImportarLojaV1 = await LojaService.importarLoja(
            user.restaurantes.basic_token,
            user?.restaurantes.dominio
          );
          if (respImportarLojaV1 === true) {
            const lojaAtual: any = user.restaurantes;

            let restauranteAux: Lojas.RestauranteInput = {
              aberto: lojaAtual.aberto,
              api_escrita: 'string',
              api_leitura: 'string',
              ativo: lojaAtual.ativo,
              basic_token: lojaAtual.basic_token,
              bloqueio: lojaAtual.bloqueio,
              cnpj: lojaAtual.cnpj,
              cozinha: {
                id: lojaAtual.cozinha.id,
              },
              dominio: lojaAtual.dominio,
              endereco: {
                bairro: lojaAtual.endereco.bairro,
                cep: lojaAtual.endereco.cep,
                cidade: {
                  id: lojaAtual.endereco.cidade.id,
                },
                complemento: lojaAtual.endereco.complemento,
                logradouro: lojaAtual.endereco.logradouro,
                numero: lojaAtual.endereco.numero,
              },
              ie: lojaAtual.ie,
              nome: lojaAtual.nome,
              taxaFrete: Number(lojaAtual.taxaFrete),
              flag_Techsapp_Sincronizado: true,
              tp_amb: lojaAtual.tp_amb,
              crt: lojaAtual.crt,
            };

            let respImportarProdutos = LojaService.importarProdutosTechsappNovos(
              lojaAtual.id,
              lojaAtual.dominio,
              lojaAtual.basic_token
            );

            notification.success({
              message: 'Sucesso',
              description: 'Importação em andamento!',
              top: 80,
            });

            closeModal();
          }
        }
      }}
      onCancel={closeModal}
      okText='Confirmar'
      cancelText='Cancelar'
    >
      <p>Deseja sincronizar novos produtos?</p>
    </Modal>
  );
};

export default ModalConfirmacaoSincNovosProdutos;
