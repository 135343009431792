import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useAuth from '../../core/hooks/useAuth';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import useProduto from '../../core/hooks/useProduto';
import CategoriaService from '../../sdk/services/Categoria.service';
import EditarProduto from '../features/produtos/EditarProduto';

function EditarProdutosView() {
  usePageTitle('Editar Produtos');
  useBreadcrumb('Home/Produtos/Editar');
  let { user } = useAuth();
  const parametroUrl = useParams<{ produtoId: string; restauranteId: string }>();
  const [categorias, setCategorias] = useState<any>([]);
  let { produto, fetchProduto } = useProduto();

  useEffect(() => {
    fetchProduto(parametroUrl.produtoId, parametroUrl.restauranteId);
  }, [fetchProduto]);

  if (!produto) return <Skeleton />;
  return (
    <>
      <EditarProduto produto={produto}></EditarProduto>
    </>
  );
}

export default EditarProdutosView;
