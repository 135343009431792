import { Col, Form, Input, Row, Select, Button, notification } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../core/hooks/useAuth';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import { Lojas } from '../../sdk/@types/Restaurantes';
import LojaService from '../../sdk/services/Loja.service';

function HomeView() {
  usePageTitle('Home');
  useBreadcrumb('Home');
  const { user } = useAuth();

  return <></>;
}

export default HomeView;
