import axios from 'axios';
import qs from 'qs';

const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_BASE_URL = process.env.REACT_APP_AUTH_SERVER_BASE_URL;

const authServer = axios.create({
  baseURL: AUTH_BASE_URL,
});

authServer.interceptors.response.use(undefined, async (error) => {
  //console.log('error');
  //console.log(error.response);

  if (error?.response?.status === 401) {
    AuthService.imperativelySendToLogout();
  }
  return Promise.reject(error);
});

export interface OAuthAuthorizationTokenResponse {
  access_token: string;
  refresh_token: string;
  token_type: 'bearer' | string;
  expires_in: number;
  scope: string;
  [key: string]: string | number;
}

export default class AuthService {
  public static imperativelySendToLogout() {
    window.localStorage.clear();
    window.location.href = `${AUTH_BASE_URL}/logout?returnTo=${APP_BASE_URL}`;
  }

  public static async getFirstAccessTokens(config: {
    code: string;
    redirectUri: string;
  }) {
    const data = {
      code: config.code,
      redirect_uri: config.redirectUri,
      grant_type: 'authorization_code',
    };

    const encodedData = qs.stringify(data);
    // web dGVjaG5mY2Vfd2ViOg==
    // local dGVjaG5mY2VfbG9jYWw6
    return authServer
      .post<OAuthAuthorizationTokenResponse>('/oauth/token', encodedData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic dGVjaG5mY2Vfd2ViOg==',
        },
      })
      .then((res) => res.data);
  }

  public static getLoginScreenUrl() {
    // local technfce_local
    // online technfce_web
    const config = qs.stringify({
      response_type: 'code',
      client_id: 'technfce_web',
      redirect_uri: `${APP_BASE_URL}/authorize`,
      state: 'abc',
    });

    return `${AUTH_BASE_URL}/oauth/authorize?${config}`;
  }

  public static async imperativelySendToLoginScreen() {
    const loginUrl = this.getLoginScreenUrl();

    // imperativo
    // gera efeito colateral
    window.location.href = loginUrl;
  }

  public static getAccessToken() {
    return window.localStorage.getItem('accessToken');
  }
  public static setAccessToken(token: string) {
    return window.localStorage.setItem('accessToken', token);
  }
}
