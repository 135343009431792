// Nfe.service.ts
import transformarDados from '../../app/funcoes/TransformarDados';
import { Nfe } from '../@types/Nfe';
import { Lojas } from '../@types/Restaurantes';
import { Usuarios } from '../@types/Usuarios';
import Service from '../Service';

class NfeService extends Service {
  static cancelarNota(chave: any, tpamb: string) {
    return this.HttpTechNFCE.post<any>(`v1/nota/cancelarNota/${chave}/${tpamb}`, {
      mensagem: 'Cancelado pois haviam erros no documento.',
    }).then(this.getData);
  }

  static atualizarStatusTechfood(chave: any, status: string) {
    return this.HttpApiTechfood.put<any>(`/v1/cupom-fiscal/${chave}/${status}`, {}).then(
      this.getData
    );
  }
}

export default NfeService;
