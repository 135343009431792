import {
  ContainerOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FormOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Modal, Button, Row, Col, notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import FormularioDeProdutosSimplificado from '../app/features/produtos/FormularioDeProdutosSimplificado';
import FormularioUsuario from '../app/features/FormularioUsuario';
import useProduto from '../core/hooks/useProduto';

import { Usuarios } from '../sdk';

const ModalProduto = (props: {
  produtoId: string;
  restauranteId: string;
  quandoFinalizar: () => any;
}) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Form de cadastro de produtos');
  const [showCreationModal, setShowCreationModal] = useState(false);
  const openCreationModal = useCallback(() => setShowCreationModal(true), []);
  const closeCreationModal = useCallback(() => setShowCreationModal(false), []);
  const history = useHistory();
  //fazer a busca
  const { produto, fetchProduto } = useProduto();

  useEffect(() => {
    fetchProduto(props.produtoId, props.restauranteId);
  }, [fetchProduto]);

  return (
    <>
      <div style={{ display: 'flex', gap: '5px', justifyContent: 'right' }}>
        <Button size='small' icon={<EditOutlined />} onClick={openCreationModal} />
        <Button
          size='small'
          icon={<ContainerOutlined />}
          onClick={() => {
            history.push(`/produtos/editar/${props.produtoId}/${props.restauranteId}`);
          }}
        />
      </div>
      <Modal
        title='Produtos'
        footer={null}
        visible={showCreationModal}
        onCancel={closeCreationModal}
        destroyOnClose
      >
        <FormularioDeProdutosSimplificado
          onSuccess={() => {
            closeCreationModal();
            props.quandoFinalizar();
            notification.success({
              message: 'Produto atualizado com sucesso',
            });
          }}
          produto={produto}
          produtoId={props.produtoId}
          restauranteId={props.restauranteId}
        ></FormularioDeProdutosSimplificado>
      </Modal>
    </>
  );
};

export default ModalProduto;
