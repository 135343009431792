import TabelaPedidos from '../../componentes/TabelaPedidos';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaPedidosView() {
  usePageTitle('Lista de Pedidos');
  useBreadcrumb('Home/Pedidos/Consulta');
  return <TabelaPedidos></TabelaPedidos>;
}

export default ConsultaPedidosView;
