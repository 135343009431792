import { useEffect } from 'react';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import FormularioDeCategoria from '../features/FormularioDeCategoria';
import { useParams } from 'react-router-dom';
import useCategoria from '../../core/hooks/useCategoria';
import { Skeleton } from 'antd';

function EditarCategoriaView() {
  usePageTitle('Editar Categorias');
  useBreadcrumb('Home/Categorias/Editar');


  const parametroUrl = useParams<{categoriaId: string;}>();
  const { categoria, fetchCategoria } = useCategoria();

  useEffect(() => {
    fetchCategoria(parametroUrl.categoriaId);
  }, [fetchCategoria]);

  if (!categoria) return <Skeleton />;
  return <FormularioDeCategoria categoria={categoria}></FormularioDeCategoria>;

  
}

export default EditarCategoriaView;
