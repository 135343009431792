import { Button } from 'antd';

import { WhatsAppOutlined } from '@ant-design/icons';
import { Pedidos } from '../sdk/@types/Pedidos';

function transformarPedidos(
  cupom: Pedidos.CupomCompleto
): Pedidos.CupomCompletoPedidoDecodificado {
  const data: Pedidos.CupomCompletoPedidoDecodificado = {
    cupomCompleto: cupom,
    pedidoDecodificado: JSON.parse(atob(cupom?.pedido)),
  };
  return data;
}

export default transformarPedidos;
