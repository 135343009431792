import {
  EyeOutlined,
  FilePdfOutlined,
  MailOutlined,
  PrinterOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Col,
  Select,
  Table,
  Form,
  Card,
  Input,
  Space,
  notification,
  Button,
  Tooltip,
} from 'antd';

import moment from 'moment';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useAuth from '../core/hooks/useAuth';
import useCupom from '../core/hooks/useCupom';
import { updateUser } from '../core/store/Auth.slice';
import { UserService } from '../sdk';
import { Pedidos } from '../sdk/@types/Pedidos';
import { Usuarios } from '../sdk/@types/Usuarios';
import CupomService from '../sdk/services/Cupom.service';
import PedidosService from '../sdk/services/Pedidos.service';
import ModalCupons from './ModalCupons';
import transformarClientes from './TransformarClientes';
import transformaTabelaCupons from './TransformaTabelaCupons';
import xmlIcon from '../assets/xml-_1_.svg';
import { ColumnProps } from 'antd/lib/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { userInfo } from 'os';

export default function TabelaCupons() {
  let { user } = useAuth();
  const dispatch = useDispatch();
  const [lojas, setLojas] = useState<Usuarios.comboLojas[]>();
  const [cupons, setCupons] = useState<Pedidos.tabelaCuponsFiscais[]>();
  const [pagina, setPagina] = useState<number>(0);
  const [total, setTotal] = useState(0);

  const [restauranteAtual, setRestauranteAtual] = useState<Usuarios.ListaRestaurantes>();

  const [restauranteSelecionado, setRestauranteSelecionado] =
    useState<Usuarios.comboLojas>();

  let id = 'MjAyMjAyMjUxMTI4MjBAaG9tb2xvZ2FjYW8=';

  const [error, setError] = useState<Error>();

  const [dadosCarregados, setDadosCarregados] = useState<boolean>(false);

  const { Option } = Select;
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [filtroCupons, setFiltroCupons] = useState<string>('AUTORIZADO');
  const [tpamb, setTpamb] = useState<string>('');
  const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const onSearch = (value: string) => {};
  const handleRefreshClick = () => {
    setRefresh(!refresh);
    setLoading(true);
  };

  let tokenTechsapp = '';
  useEffect(() => {
    if (user?.grupos?._embedded.grupos[0].id === 1) {
      setLojas(trataLojasUsuario(user));
    }
    if (user?.grupos?._embedded.grupos[0].id === 5) {
      UserService.getAllRestaurantes()
        .then(trataLojas)
        .then(setLojas)
        .catch((error) => {
          setError(new Error(error.message));
        });
    }
  }, [tokenTechsapp]);

  useEffect(() => {
    if (user?.restaurantes?.id && filtroCupons && pagina !== undefined) {
      setLoading(true);
      setTpamb(user?.restaurantes?.tp_amb);
      PedidosService.getListaCupomFiscal(
        user?.restaurantes?.id,
        filtroCupons,
        pagina,
        user?.restaurantes?.tp_amb
      )
        .then((cupons) => {
          setTotal(cupons.page.totalElements);
          setCupons(transformaTabelaCupons(cupons));
          setDadosCarregados(true);
        })
        .catch((error) => {
          setError(new Error(error.message));
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [restauranteAtual, user?.restaurantes?.id, refresh, filtroCupons, pagina]);

  function trataLojas(
    lojas: Usuarios.ArrayDeRestaurantesDoUsuario
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    lojas._embedded.restaurantes.map((restaurante) => {
      const restauranteAux: Usuarios.comboLojas = {
        id: restaurante.id,
        index: indexCombo,
        nome: restaurante.nome,
        dominio: restaurante.dominio,
        token: restaurante.basic_token,
        cnpj: restaurante.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    });

    return comboRestaurantes;
  }

  function trataLojasUsuario(
    usuario: Usuarios.UsuarioDetalhadoGrupo
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    if (usuario.restaurantes) {
      const restauranteAux: Usuarios.comboLojas = {
        id: usuario.restaurantes.id,
        index: indexCombo,
        nome: usuario.restaurantes.nome,
        dominio: usuario.restaurantes.dominio,
        token: usuario.restaurantes.basic_token,
        cnpj: usuario.restaurantes.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    }

    return comboRestaurantes;
  }

  function trataRestauranteAtual(
    restaurantes: Usuarios.ListaRestaurantes
  ): Usuarios.ListaRestaurantes {
    if (user && restaurantes) {
      let userAux = {
        grupos: user.grupos,
        usuario: user.usuario,
        restaurantes: restaurantes,
      };
      user = userAux;

      userAux.restaurantes = restaurantes;
      dispatch(updateUser(userAux));
    }
    return restaurantes;
  }
  const onChange = (value: any) => {
    value = JSON.parse(value);

    setRestauranteSelecionado(value as Usuarios.comboLojas);
    UserService.getRestaurantesByID(value.id)
      .then(trataRestauranteAtual)
      .then(setRestauranteAtual);
  };

  function trataNota(dados: any): any {
    return dados;
  }

  const getColumnSearchProps = (
    dataIndex: keyof any,
    displayName?: string
  ): ColumnProps<any> => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          //placeholder={`Buscar ${displayName || dataIndex}`}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),
    // @ts-ignore
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '',
  });

  const columns: any = [
    {
      title: 'Pedido',
      dataIndex: 'token',
      key: 'token',
      ...getColumnSearchProps('token', 'Token'),
    },
    {
      title: 'Serie',
      dataIndex: 'serie',
      key: 'serie',
      ...getColumnSearchProps('serie', 'serir'),
    },
    {
      title: 'NF',
      dataIndex: 'nnf',
      ...getColumnSearchProps('nnf', 'nnf'),
      key: 'nnf',
    },
    {
      title: 'Data',
      dataIndex: 'dataEmissao',
      ...getColumnSearchProps('dataEmissao', 'dataEmissao'),
      key: 'dataEmissao',
      render(dataEmissao: string) {
        return moment(dataEmissao).format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      title: 'Cliente',
      dataIndex: 'nome',
      key: 'cliente',
      ...getColumnSearchProps('nome', 'nome'),
    },
    {
      title: 'Valor',
      dataIndex: 'total',
      key: 'total',
      render(total: string) {
        var valorAux = 'R$' + parseFloat(total).toFixed(2).replace('.', ',');
        return valorAux;
      },
    },
    {
      title: 'Ação',
      dataIndex: 'chaveDeAcesso',
      width: 80,
      key: 'chaveDeAcesso',
      //fixed: 'right',

      render(chaveDeAcesso: string) {
        return (
          <>
            <div style={{ display: 'flex', gap: '5px', justifyContent: 'right' }}>
              {filtroCupons === 'CANCELADO' ? (
                <Tooltip title={'Baixar XML Cancelado'} placement={'top'}>
                  <Button
                    size='small'
                    onClick={async function () {
                      const cupom = await CupomService.getCupomByChave(chaveDeAcesso);
                      if (cupom._embedded) {
                        var xmltext = cupom._embedded.historico[0].xmlCancelado;

                        var filename = 'CANC_' + chaveDeAcesso + '.xml';
                        var pom = document.createElement('a');
                        var bb = new Blob([xmltext], { type: 'text/plain' });

                        pom.setAttribute('href', window.URL.createObjectURL(bb));
                        pom.setAttribute('download', filename);

                        pom.dataset.downloadurl = [
                          'text/plain',
                          pom.download,
                          pom.href,
                        ].join(':');
                        pom.draggable = true;
                        pom.classList.add('dragout');

                        pom.click();
                      } else {
                        notification.error({
                          message: 'Não foi possível baixar o XML',
                        });
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faFileExcel} />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title={'Baixar PDF'} placement={'top'}>
                  <Button
                    size='small'
                    icon={<FilePdfOutlined />}
                    onClick={async function () {
                      const cupom = await CupomService.getCupomByChave(chaveDeAcesso);
                      if (cupom._embedded) {
                        // The Base64 string of a simple PDF file
                        var b64 = cupom._embedded.historico[0].pdfBase64;

                        // Decode Base64 to binary and show some information about the PDF file (note that I skipped all checks)
                        var bin = atob(b64);

                        // Embed the PDF into the HTML page and show it to the user
                        var obj = document.createElement('object');
                        obj.style.width = '100%';
                        obj.style.height = '842pt';
                        obj.type = 'application/pdf';
                        obj.data = 'data:application/pdf;base64,' + b64;
                        document.body.appendChild(obj);

                        // Insert a link that allows the user to download the PDF file
                        var link = document.createElement('a');
                        link.innerHTML = 'Download PDF file';
                        link.download = 'PDF_' + chaveDeAcesso + '.pdf';
                        link.href = 'data:application/octet-stream;base64,' + b64;
                        document.body.appendChild(link);
                        link.click();
                      } else {
                        notification.error({
                          message: 'Não foi possível baixar o PDF',
                        });
                      }
                    }}
                  ></Button>
                </Tooltip>
              )}

              <Tooltip title={'Baixar XML'} placement={'top'}>
                <Button
                  size='small'
                  onClick={async function () {
                    const cupom = await CupomService.getCupomByChave(chaveDeAcesso);
                    if (cupom._embedded) {
                      var xmltext = cupom._embedded.historico[0].xmlAutorizado;

                      var filename = 'NFCE_' + chaveDeAcesso + '.xml';
                      var pom = document.createElement('a');
                      var bb = new Blob([xmltext], { type: 'text/plain' });

                      pom.setAttribute('href', window.URL.createObjectURL(bb));
                      pom.setAttribute('download', filename);

                      pom.dataset.downloadurl = [
                        'text/plain',
                        pom.download,
                        pom.href,
                      ].join(':');
                      pom.draggable = true;
                      pom.classList.add('dragout');

                      pom.click();
                    } else {
                      notification.error({
                        message: 'Não foi possível baixar o XML',
                      });
                    }
                  }}
                >
                  <img src={xmlIcon} style={{ width: '14px', marginTop: '-4px' }} />
                </Button>
              </Tooltip>

              <ModalCupons
                quandoFinalizar={() => {
                  setRefresh(!refresh);
                }}
                chaveDeAcesso={chaveDeAcesso}
                tpamb={tpamb}
              ></ModalCupons>
            </div>
          </>
        );
      },
    },
  ];
  const onChangeSelectCupons = (value: any) => {
    setFiltroCupons(value);
  };

  const handleTableChange = (pagination: any) => {
    setPagina(pagination - 1);
  };
  return (
    <>
      {user?.grupos?._embedded.grupos[0].id === 5 ? (
        <Col xs={24} lg={13}>
          <Form.Item label={'Loja'} name={'loja'} style={{ width: '70%' }}>
            <Select
              placeholder={'Selecione o loja'}
              showSearch
              onChange={onChange}
              onSearch={onSearch}
              optionFilterProp='children'
              defaultValue={lojas ? lojas[0].nome : ''}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {lojas?.map((restaurante) => (
                <Select.Option value={JSON.stringify(restaurante)}>
                  {restaurante.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        ''
      )}
      <>
        <Button
          type='primary'
          icon={<ReloadOutlined />}
          onClick={handleRefreshClick}
          style={{ width: '50px' }}
        />

        <Select defaultValue={'AUTORIZADO'} onChange={onChangeSelectCupons}>
          <Select.Option value={'AUTORIZADO'}>Autorizado</Select.Option>
          <Select.Option value={'CANCELADO'}>Cancelado</Select.Option>
        </Select>

        <Table
          dataSource={cupons ? cupons : []}
          columns={columns}
          loading={loading}
          pagination={{
            total,
            pageSize: 10,
            showSizeChanger: false,
            onChange: handleTableChange,
          }}
        />
      </>
    </>
  );
}
