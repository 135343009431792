import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Input, notification, Space, Table } from 'antd';
import useAuth from '../../core/hooks/useAuth';
import ProdutosService from '../../sdk/services/Produtos.service';
import { SearchOutlined, ReloadOutlined, ArrowDownOutlined } from '@ant-design/icons';
import axios from 'axios';
import usePageTitle from '../../core/hooks/usePageTitle';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import CurrencyInput from '../components/CurrencyInput';
import { useHistory } from 'react-router-dom';
import { exists } from 'fs';
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  usePageTitle('Manutenção de Produtos');
  useBreadcrumb('Home/Produtos/Manutenção');
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        /*  rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}*/
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const MyEditableTable = () => {
  const [transacaoPendente, setTransacaoPendente] = useState(true);
  let { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [transactionId, setTransactionId] = useState('');
  const [produtos, setProdutos] = useState([]);

  const [filtroCategoria, setFiltroCategoria] = useState([]);
  const [filtroNome, setFiltroNome] = useState([]);

  const [executarBusca, setExecutarBusca] = useState(true);
  const [podeAtualizar, setPodeAtualizar] = useState(false);

  useEffect(() => {
    async function fetchData() {
      console.log(transacaoPendente);
      if (transacaoPendente === true && user?.restaurantes?.id) {
        var cont = 0;
        setLoading(true);
        setPodeAtualizar(false);
        var resp = await ProdutosService.getTransacaoPendente(user?.restaurantes?.id);
        if (resp.length > 0) {
          window.alert('Existem alterações sendo processadas.');
        }
        while (cont < 6) {
          cont++;
          resp = await ProdutosService.getTransacaoPendente(user?.restaurantes?.id);
          console.log(resp);
          console.log(resp.length);
          if (resp.length === 0) {
            cont = 7;
            setTransacaoPendente(false);
          } else {
            notification.info({
              message: 'Informação',
              description: 'Existem alterações sendo processadas.',
            });
            if (cont >= 6) {
              if (
                window.confirm(
                  'Deseja cancelar o processamento?\nClique Ok para cancelar'
                ) === true
              ) {
                await ProdutosService.cancelarTransacaoPendente(user?.restaurantes?.id);
                cont = 0;
              } else {
                cont = 0;
              }

              cont = 0;
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 20000));
        }

        if (cont === 6) {
          window.alert('O Processamento ainda esta ocorrendo!');
        }
      }
    }

    fetchData();
  }, [user?.restaurantes, transacaoPendente]);

  const handleRefreshClick = () => {
    setRefresh(!refresh);
    setLoading(true);
    console.log('Dados atualizados');
  };
  useEffect(() => {
    async function fetchData() {
      console.log('dentro do fetchData');
      try {
        var cont = 0;
        while (cont < 6) {
          await new Promise((resolve) => setTimeout(resolve, 20000));

          console.log('getTransacaoById');
          const result = await ProdutosService.getTransacaoById(
            user?.restaurantes?.id,
            transactionId
          );
          console.log(result);
          //setTransactionId(result);

          if (result.status === '2') {
            notification.info({
              message: 'Informação',
              description: 'As alterações estão sendo processadas.',
            });
          }
          if (result.status === '3') {
            setExecutarBusca(false);
            handleRefreshClick();
            notification.success({
              message: 'Sucesso',
              description: 'As alterações foram concluidas.',
            });

            cont = 7;
            break;
          }
          cont++;
        }

        if (cont === 6) {
          window.alert('O Processamento ainda esta ocorrendo!');
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (user?.restaurantes?.id && transactionId && executarBusca) {
      fetchData();
    }
  }, [user?.restaurantes?.id, transactionId, executarBusca]);

  useEffect(() => {
    console.log('transacaoPendente', transacaoPendente);
    if (user?.restaurantes?.id && transacaoPendente === false) {
      console.log('buscando os produtos');
      setLoading(true);
      ProdutosService.getProdutosEspecifico(user?.restaurantes?.id)
        .then((produtos) => {
          if (produtos && produtos._embedded) {
            produtos._embedded.produtos.forEach((produto) => {
              produto.key = produto.id;
            });

            console.log(produtos);
            return produtos._embedded.produtos;
          }
        })
        .then(setProdutos)
        .catch((error) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
          setPodeAtualizar(true);
          console.log(produtos);
        });
    }
  }, [user?.restaurantes, refresh, transacaoPendente]);

  const getColumnSearchProps = (dataIndex, displayName) => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          //placeholder={`Buscar ${displayName || dataIndex}`}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            if (dataIndex === 'nome') {
              setFiltroNome(selectedKeys);
            }

            if (dataIndex === 'categoria') {
              setFiltroCategoria(selectedKeys);
            }
            confirm();
          }}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => {
              if (dataIndex === 'nome') {
                setFiltroNome(selectedKeys);
              }

              if (dataIndex === 'categoria') {
                setFiltroCategoria(selectedKeys);
              }
              confirm();
            }}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),

    onFilter: (value, record) => {
      if (dataIndex === 'categoria') {
        const filtered =
          record[dataIndex] && record[dataIndex].nome
            ? record[dataIndex].nome.toLowerCase().includes(value.toLowerCase())
            : '';

        return filtered;
      } else {
        const filtered = record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '';
        return filtered;
      }
    },
  });

  const [count, setCount] = useState(2);

  const [unidadeAtual, setUnidadeAtual] = useState('');
  const [cfopAtual, setCfopAtual] = useState('');
  const [ncmAtual, setNcmAtual] = useState('');
  const [precoAtual, setPrecoAtual] = useState('');
  const [cstAtual, setCstAtual] = useState('');
  const alterarTodasUnidades = () => {
    if (filtroCategoria.length === 0 && filtroNome.length === 0) {
      const novosDados = produtos.map((item) => {
        return {
          ...item,
          unidade: unidadeAtual,
          modificado: true,
        };
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 1 && filtroNome.length === 1) {
      const novosDados = produtos.map((item) => {
        if (
          item.categoria.nome
            .toString()
            .toLowerCase()
            .includes(filtroCategoria[0].toLowerCase()) &&
          item.nome.toString().toLowerCase().includes(filtroNome[0].toLowerCase())
        ) {
          return {
            ...item,
            unidade: unidadeAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 1 && filtroNome.length === 0) {
      const novosDados = produtos.map((item) => {
        if (
          item.categoria.nome
            .toString()
            .toLowerCase()
            .includes(filtroCategoria[0].toLowerCase())
        ) {
          return {
            ...item,
            unidade: unidadeAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 0 && filtroNome.length === 1) {
      const novosDados = produtos.map((item) => {
        if (item.nome.toString().toLowerCase().includes(filtroNome[0].toLowerCase())) {
          return {
            ...item,
            unidade: unidadeAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }
  };

  const alterarTodosNcms = () => {
    if (filtroCategoria.length === 0 && filtroNome.length === 0) {
      const novosDados = produtos.map((item) => {
        return {
          ...item,
          ncm: ncmAtual,
          modificado: true,
        };
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 1 && filtroNome.length === 1) {
      const novosDados = produtos.map((item) => {
        if (
          item.categoria.nome
            .toString()
            .toLowerCase()
            .includes(filtroCategoria[0].toLowerCase()) &&
          item.nome.toString().toLowerCase().includes(filtroNome[0].toLowerCase())
        ) {
          return {
            ...item,
            ncm: ncmAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 1 && filtroNome.length === 0) {
      const novosDados = produtos.map((item) => {
        if (
          item.categoria.nome
            .toString()
            .toLowerCase()
            .includes(filtroCategoria[0].toLowerCase())
        ) {
          return {
            ...item,
            ncm: ncmAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 0 && filtroNome.length === 1) {
      const novosDados = produtos.map((item) => {
        if (item.nome.toString().toLowerCase().includes(filtroNome[0].toLowerCase())) {
          return {
            ...item,
            ncm: ncmAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }
  };

  const alterarTodosCfops = () => {
    console.log(filtroNome);
    if (filtroCategoria.length === 0 && filtroNome.length === 0) {
      const novosDados = produtos.map((item) => {
        return {
          ...item,
          cfop: cfopAtual,
          modificado: true,
        };
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 1 && filtroNome.length === 1) {
      const novosDados = produtos.map((item) => {
        if (
          item.categoria.nome
            .toString()
            .toLowerCase()
            .includes(filtroCategoria[0].toLowerCase()) &&
          item.nome.toString().toLowerCase().includes(filtroNome[0].toLowerCase())
        ) {
          return {
            ...item,
            cfop: cfopAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 1 && filtroNome.length === 0) {
      const novosDados = produtos.map((item) => {
        if (
          item.categoria.nome
            .toString()
            .toLowerCase()
            .includes(filtroCategoria[0].toLowerCase())
        ) {
          return {
            ...item,
            cfop: cfopAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 0 && filtroNome.length === 1) {
      const novosDados = produtos.map((item) => {
        if (item.nome.toString().toLowerCase().includes(filtroNome[0].toLowerCase())) {
          return {
            ...item,
            cfop: cfopAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }
  };

  const alterarTodosCsts = () => {
    if (filtroCategoria.length === 0 && filtroNome.length === 0) {
      const novosDados = produtos.map((item) => {
        return {
          ...item,
          cst: cstAtual,
          modificado: true,
        };
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 1 && filtroNome.length === 1) {
      const novosDados = produtos.map((item) => {
        if (
          item.categoria.nome
            .toString()
            .toLowerCase()
            .includes(filtroCategoria[0].toLowerCase()) &&
          item.nome.toString().toLowerCase().includes(filtroNome[0].toLowerCase())
        ) {
          return {
            ...item,
            cst: cstAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 1 && filtroNome.length === 0) {
      const novosDados = produtos.map((item) => {
        if (
          item.categoria.nome
            .toString()
            .toLowerCase()
            .includes(filtroCategoria[0].toLowerCase())
        ) {
          return {
            ...item,
            cst: cstAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 0 && filtroNome.length === 1) {
      const novosDados = produtos.map((item) => {
        if (item.nome.toString().toLowerCase().includes(filtroNome[0].toLowerCase())) {
          return {
            ...item,
            cst: cstAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }
  };

  const alterarTodosPrecos = () => {
    if (filtroCategoria.length === 0 && filtroNome.length === 0) {
      const novosDados = produtos.map((item) => {
        return {
          ...item,
          preco: precoAtual,
          modificado: true,
        };
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 1 && filtroNome.length === 1) {
      const novosDados = produtos.map((item) => {
        if (
          item.categoria.nome
            .toString()
            .toLowerCase()
            .includes(filtroCategoria[0].toLowerCase()) &&
          item.nome.toString().toLowerCase().includes(filtroNome[0].toLowerCase())
        ) {
          return {
            ...item,
            preco: precoAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 1 && filtroNome.length === 0) {
      const novosDados = produtos.map((item) => {
        if (
          item.categoria.nome
            .toString()
            .toLowerCase()
            .includes(filtroCategoria[0].toLowerCase())
        ) {
          return {
            ...item,
            preco: precoAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }

    if (filtroCategoria.length === 0 && filtroNome.length === 1) {
      const novosDados = produtos.map((item) => {
        if (item.nome.toString().toLowerCase().includes(filtroNome[0].toLowerCase())) {
          return {
            ...item,
            preco: precoAtual,
            modificado: true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      console.log('novosDados', novosDados);
      setProdutos(novosDados);
    }
  };
  const defaultColumns = [
    {
      title: 'nome',
      dataIndex: 'nome',
      ...getColumnSearchProps('nome', 'nome'),
      key: 'nome',
    },
    {
      title: 'categoria',
      dataIndex: 'categoria',
      ...getColumnSearchProps('categoria', 'categoria'),
      key: 'categoria',
      render(categoria) {
        if (categoria) {
          return categoria.nome;
        } else {
          return '';
        }
      },
    },
    {
      title: (
        <div>
          NCM
          <br />
          <br />
          <Input
            placeholder='Novo NCM'
            value={ncmAtual}
            onChange={(e) => setNcmAtual(e.target.value)}
            style={{ width: '100px' }}
          />
          <Button
            type='primary'
            onClick={alterarTodosNcms}
            icon={<ArrowDownOutlined />}
          />
        </div>
      ),
      dataIndex: 'ncm',
      width: '170px',
      editable: true,
      render(ncm) {
        if (ncm) {
          return ncm;
        } else {
          return '0';
        }
      },
    },
    {
      title: (
        <div>
          CFOP
          <br />
          <br />
          <Input
            placeholder='Novo CFOP'
            value={cfopAtual}
            onChange={(e) => setCfopAtual(e.target.value)}
            style={{ width: '100px' }}
          />
          <Button
            type='primary'
            onClick={alterarTodosCfops}
            icon={<ArrowDownOutlined />}
          />
        </div>
      ),
      dataIndex: 'cfop',
      width: '170px',
      editable: true,
      render(cfop) {
        if (cfop) {
          return cfop;
        } else {
          return '0';
        }
      },
    },
    {
      title: (
        <div>
          CST
          <br />
          <br />
          <Input
            placeholder='Novo CST'
            value={cstAtual}
            onChange={(e) => setCstAtual(e.target.value)}
            style={{ width: '100px' }}
          />
          <Button
            type='primary'
            onClick={alterarTodosCsts}
            icon={<ArrowDownOutlined />}
          />
        </div>
      ),
      dataIndex: 'cst',
      editable: true,
      width: '170px',
      render(cst) {
        if (cst) {
          return cst;
        } else {
          return '0';
        }
      },
    },
    {
      title: (
        <div>
          Unidade
          <br />
          <br />
          <Input
            placeholder='Nova Unidade'
            value={unidadeAtual}
            onChange={(e) => setUnidadeAtual(e.target.value)}
            style={{ width: '100px' }}
          />
          <Button
            type='primary'
            onClick={alterarTodasUnidades}
            icon={<ArrowDownOutlined />}
          />
        </div>
      ),

      dataIndex: 'unidade',
      editable: true,
      width: '170px',
    },
    {
      title: (
        <div>
          Preço
          <br />
          <br />
          <CurrencyInput
            onChange={(_, value) => {
              console.log(value);
              setPrecoAtual(value);
            }}
            style={{ width: '100px' }}
          />
          <Button
            type='primary'
            onClick={alterarTodosPrecos}
            icon={<ArrowDownOutlined />}
          />
        </div>
      ),
      dataIndex: 'preco',
      render(total) {
        var valorAux = 'R$' + parseFloat(total).toFixed(2).replace('.', ',');
        return valorAux;
      },
      width: '170px',
      editable: true,
    },
  ];

  const handleSave = (row) => {
    const newData = [...produtos];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    item.modificado = true;
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setProdutos(newData);
    console.log(newData);
  };
  const fazerPost = async () => {
    try {
      setLoading(true);
      setPodeAtualizar(false);

      var dataParaEnviar = [];
      console.log('mapeando o array');
      produtos.map((produto) => {
        if (produto.modificado) {
          produto._links = undefined;
          produto.modificado = undefined;
          produto.key = undefined;
          dataParaEnviar.push(produto);
        }
      });
      console.log(dataParaEnviar);
      if (dataParaEnviar.length > 0) {
        const transactionId = await ProdutosService.geraTransacao(user?.restaurantes?.id);
        console.log(transactionId);
        let cont = 0;
        let transactionInfo;
        do {
          ProdutosService.editarLista(
            user?.restaurantes?.id,
            transactionId,
            dataParaEnviar
          );

          if ((user?.restaurantes?.id, transactionId)) {
            transactionInfo = await ProdutosService.getTransacaoById(
              user?.restaurantes?.id,
              transactionId
            );
            if (transactionInfo.status !== '1') {
              console.log(transactionInfo);
              setTransactionId(transactionId);
              setExecutarBusca(true);
              notification.info({
                message: 'Informação',
                description: 'As alterações estão sendo processadas.',
              });
            } else {
              await new Promise((resolve) => setTimeout(resolve, 10000));
              cont++;
              console.log(cont + 'tentativa');
              if (cont === 6) {
                setLoading(false);
                notification.error({
                  message: 'Erro',
                  description:
                    'Não foi possível o envio das alterações, tente novamente mais tarde.',
                });
              }
            }
          }
        } while (transactionInfo.status === '1' && cont < 6);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div>
      <Button
        type='primary'
        icon={<ReloadOutlined />}
        onClick={handleRefreshClick}
        disabled={!podeAtualizar}
        style={{ width: '50px', marginRight: '20px' }}
      />
      <Button type='primary' onClick={() => fazerPost()} loading={loading}>
        Salvar
      </Button>

      <Table
        key={'tabelaProdutosManutencao'}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={produtos ? produtos : []}
        columns={columns}
        loading={loading}
      />
    </div>
  );
};
export default MyEditableTable;
