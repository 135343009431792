import { userInfo } from 'os';
import useAuth from '../../core/hooks/useAuth';
import { Pedidos } from '../@types/Pedidos';
import Service from '../Service';

class ProdutosService extends Service {
  static getProdutos(restauranteId: string) {
    return this.HttpApiTechfood.get<any>(
      `/v1/restaurantes/${restauranteId}/produtos`
    ).then(this.getData);
  }

  static getProdutosEspecifico(restauranteId: string) {
    return this.HttpApiTechfood.get<any>(
      `/v1/restaurantes/${restauranteId}/produtos/config`
    ).then(this.getData);
  }

  static getTransacaoPendente(restauranteId: string) {
    return this.HttpApiTechfood.get<any>(
      `/v1/restaurantes/${restauranteId}/produtos/getTransacaoPendenteManutencaoProdutoPorLojaeUsuario`
    ).then(this.getData);
  }
  static cancelarTransacaoPendente(restauranteId: string) {
    return this.HttpApiTechfood.delete<any>(
      `/v1/restaurantes/${restauranteId}/produtos/finalizarTransacoes`
    ).then(this.getData);
  }

  static geraTransacao(restauranteId: string) {
    return this.HttpApiTechfood.get<any>(
      `/v1/restaurantes/${restauranteId}/produtos/geraTransacao`
    ).then(this.getData);
  }
  static getTransacaoManutençãoProdutoPorLojaeUsuario(restauranteId: string) {
    return this.HttpApiTechfood.get<any>(
      `/v1/restaurantes/${restauranteId}/produtos/getTransacaoManutencaoProdutoPorLojaeUsuario`
    ).then(this.getData);
  }
  static getTransacaoById(restauranteId: string, transacaoId: string) {
    return this.HttpApiTechfood.get<any>(
      `/v1/restaurantes/${restauranteId}/produtos/transacao/${transacaoId}`
    ).then(this.getData);
  }

  static editarLista(restauranteId: string, transacaoId: string, arrayProdutos: any) {
    return this.HttpApiTechfood.put<any>(
      `/v1/restaurantes/${restauranteId}/produtos/editarLista/${transacaoId}`,
      arrayProdutos
    );
  }

  static getProdutosV1ByCodigoIntegracao(
    codigoIntegracao: string,
    plataforma: string,
    tokenTechsapp: string
  ) {
    return this.Http2.get<any>(
      `https://www.${plataforma}.com.br/backend/api/v2/produto/getByCodIntegracao/${codigoIntegracao}`,
      {
        headers: { Authorization: `Basic ${tokenTechsapp}` },
      }
    ).then(this.getData);
  }

  static excluirProdutoByID(idLoja: string, idProduto: string) {
    return this.HttpApiTechfood.delete<any>(
      `/v1/restaurantes/${idLoja}/produtos/${idProduto}/excluir`,
      {}
    ).then(this.getData);
  }

  static getProdutosTechfoodByCodigoIntegracao(
    codigoIntegracao: string,
    restauranteId: string
  ) {
    return this.HttpApiTechfood.get<any>(
      `/v1/restaurantes/${restauranteId}/produtos/buscarporcodigointegracao/${codigoIntegracao}`
    ).then(this.getData);
  }
  static cadastrarProduto(produto: any, restauranteId: string) {
    return this.HttpApiTechfood.post<any>(
      `/v1/restaurantes/${restauranteId}/produtos`,
      produto
    ).then(this.getData);
  }

  static atualizarProduto(produto: any, restauranteId: string, produtoId: string) {
    return this.HttpApiTechfood.put<any>(
      `/v1/restaurantes/${restauranteId}/produtos/${produtoId}`,
      produto
    ).then(this.getData);
  }

  static getById(produtoId: string, restauranteID: string) {
    return this.HttpApiTechfood.get<any>(
      `/v1/restaurantes/${restauranteID}/produtos/${produtoId}`
    ).then(this.getData);
  }
}

export default ProdutosService;
