import { Col, Form, Input, Row, Select, Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CategoriaService from '../../sdk/services/Categoria.service';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

interface FormularioDeCategoriaProps {
  categoria?: any;
  onSuccess?: () => any;
}

export default function FormularioDeCategoria(props: FormularioDeCategoriaProps) {
  const [nomeCategoria, setNomeCategoria] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const access_token = localStorage.getItem('accessToken');
  const [idUser ,setIdUser] = useState('');


  const [idLoja, setIDLoja] = useState('');

  const [categoriaFormulario, setCategoriaFormulario] = useState<any>(props.categoria || {});

  useEffect(() => {
    if(access_token){
      const usuario:any = jwtDecode(access_token);
      setIdUser(usuario.usuario_id);
    }
  }, [access_token]);

  useEffect(() => {
    if (!idUser) return;

    const fetchData = async () => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      };

      if (!idLoja) {
        try {
          const resp = await axios.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
          setIDLoja(resp.data.id);

        } catch (error) {
        }

   
      }
    };
    fetchData();
  }, [idUser]);

  const handleNomeCategoriaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoriaFormulario({ ...categoriaFormulario, nome: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await CategoriaService.cadastrarCategoria(categoriaFormulario, idLoja);

      if (response) {
        notification.success({
          message: 'Sucesso',
          description: 'Categoria cadastrada com sucesso',
        });
        history.push('/categoria');
      } else {
        notification.error({
          message: 'Erro',
          description: 'Erro ao cadastrar a categoria',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: 'Erro ao cadastrar a categoria',
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (props.categoria) {
      setCategoriaFormulario(props.categoria);
    }
  }, [props.categoria]);

  return (
    <Form autoComplete="off">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Nome da Categoria">
            <Input
              type="text"
              placeholder="Digite o nome da categoria"
              value={categoriaFormulario.nome || nomeCategoria}
              onChange={handleNomeCategoriaChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            Salvar Categoria
          </Button>

        &nbsp;
                <Button
                  type={'primary'}
                  htmlType={'button'}
                  style={{
                    backgroundColor: props.categoria ? 'red' : '#E5E5E5',
                    borderColor: props.categoria ? 'red' : '#E5E5E5',
                    display: props.categoria ? '' : 'none',
                  }}
                  disabled={props.categoria ? false : true}
                  onClick={async () => {
                    const respExclusão = await CategoriaService.excluirByID(
                      props.categoria.id as string,
  
                    );

                    notification.success({
                      message: 'Sucesso',
                      description: 'Categoria excluida com sucesso',
                    });
                    history.push(`/categoria`);
                  }}
                >
                  Excluir Categoria
                </Button>
              
        </Col>
      </Row>
    </Form>
  );
}