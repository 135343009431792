import { notification } from 'antd';
import axios from 'axios';
import Service from '../sdk/Service';

import AuthService from './Authorization.service';

Service.setRequestInterceptors(async (request) => {
  const accessToken = AuthService.getAccessToken();

  // injeta o token de acesso na requisição
  if (accessToken) {
    if (request.baseURL === 'https://auth2-tech.herokuapp.com') {
      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }
    if (request.baseURL === 'https://technfe-api.herokuapp.com') {
      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }

    if (request.baseURL === 'https://techfood-api.herokuapp.com') {
      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }
  }

  // console.log(request);
  return request;
});

Service.setResponseInterceptors(
  (response) => response,

  async (error) => {
    //  console.log(error);

    if (error?.response?.status === 400) {
      console.error(error);
      notification.error({
        message: 'Falha',
        description: error?.response.data.detail,
      });
      return {};
    }
    if (error?.response?.status === 401) {
      console.error(error);
      AuthService.imperativelySendToLogout();
    }
    if (error?.response?.status === 404) {
      console.error(error);
      notification.error({
        message: 'Falha',
        description: error?.response.data.userMessage,
      });
      return {};
    }
    if (error?.response?.status === 406) {
      console.error(error);
      notification.error({
        message: 'Falha',
        description: error?.response.data.detail,
      });
      return {};
    }
    if (error?.response?.status === 500) {
      console.error(error);
      notification.error({
        message: 'Falha',
        description: error?.response.data.detail,
      });
      return {};
    }

    if (error?.response?.status === 503) {
      console.error(error);
      return {};
    }

    return error;
  }
);
